import { useLocation } from 'react-router-dom';
import usePermission from "../../../hooks/usePermission";

const HeaderTitle = () => {
  const pathName = useLocation().pathname;

  const { isFreeUser } = usePermission();
  const { isFinancialUser } = usePermission();

  // 表示するhtmlを生成
  let headerTitleHtml = <></>;
  // ハザードマップ画面のヘッダー
  if (pathName === '/') headerTitleHtml = createHazardMapHeader(isFreeUser());
  // 拠点入力画面のヘッダー
  if (pathName === '/purchaseLocation') headerTitleHtml = createLocationHeader(isFinancialUser());
  // 分析結果確認画面のヘッダー
  if (pathName === '/analysisResult') headerTitleHtml = createResultHeader();

  return headerTitleHtml;
};

// ハザードマップ画面のヘッダー
const createHazardMapHeader = (isFreeUser) => {
  return (
    <div className="header-center">
      <h1 className="title">
        <span>ハザードマップ{isFreeUser && '（無償版）'}</span>
        <span>Hazard map {isFreeUser && '(free ver.)'}</span>
      </h1>
    </div>
  );
};

// 拠点入力画面のヘッダー
const createLocationHeader = (isFinancialUser) => {
  return (
    <div className="header-center">
      <h1 className="title">
        <span>{isFinancialUser ? '拠点データ入力' : 'プレミアム版ハザードマップの購入申請'}</span>
        <span>{isFinancialUser ? 'Import location data' : 'Gain Access to Premium Hazardmap'}</span>
      </h1>
    </div>
  );
};

// 分析結果確認画面のヘッダー
const createResultHeader = () => {
  return (
    <div className="header-center">
      <h1 className="title">
        <span>分析結果</span>
        <span>Analysis Result</span>
      </h1>
    </div>
  );
};

export default HeaderTitle;
