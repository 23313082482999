import React from 'react';

const Page403 = () => {
  return (
    <div id="page">
        <main className="main">
        <div className="container text-center">
          <h1>403 - forbidden</h1>
        </div>
        </main>
    </div>
  );
};

export default Page403;