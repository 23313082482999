import { useEffect, useState } from 'react';

import PanelItems from './PanelItems.js';

const PanelFinancialHiRes = (props) => {
  // チェックボックスの状態管理
  const [checkedList, setCheckedList] = useState(PanelItems.FinancialHiRes);

  // レイヤーの初期表示
  useEffect(() => {
    const currentCheckedList = JSON.parse(JSON.stringify(checkedList));
    const recurrencePeriodValues = Object.entries(currentCheckedList['recurrencePeriod'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);
    const yearScenarioValues = Object.entries(currentCheckedList['yearScenario'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);
    const riseScenarioValues = Object.entries(currentCheckedList['riseScenario'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);

    const checkedItems = [...recurrencePeriodValues, ...yearScenarioValues, ...riseScenarioValues];
    props.setLayersList('FinancialHiRes', checkedItems);
  }, []);

  // 拠点クリック時、非選択状態のチェック項目を外す
  useEffect(() => {
    if (!props.firstHiResLayerFlg) return;
    const currentCheckedList = JSON.parse(JSON.stringify(checkedList));
    // すべてのチェックボックスのチェックを外す
    for (const key in currentCheckedList.recurrencePeriod) {
      if (currentCheckedList.recurrencePeriod.hasOwnProperty(key)) {
        currentCheckedList.recurrencePeriod[key].checked = false;
      }
    }
    for (const key in currentCheckedList.yearScenario) {
      if (currentCheckedList.yearScenario.hasOwnProperty(key)) {
        currentCheckedList.yearScenario[key].checked = false;
      }
    }

    for (const key in currentCheckedList.riseScenario) {
      if (currentCheckedList.riseScenario.hasOwnProperty(key)) {
        currentCheckedList.riseScenario[key].checked = false;
      }
    }
    // 100years, 2020がオンの状態に変更
    currentCheckedList.recurrencePeriod.FinancialHiRes1_1.checked = true;
    currentCheckedList.yearScenario.FinancialHiRes2_1.checked = true;
    setCheckedList(currentCheckedList);
  }, [props.firstHiResLayerFlg]);

  // チェックボックスの状態更新
  const onCheckBox = async (e, key) => {
    let currentCheckedList = JSON.parse(JSON.stringify(checkedList));

    const PanelLists = currentCheckedList[key];
    PanelLists[e.target.id].checked = !currentCheckedList[key][e.target.id].checked;

    // RP（再現期間）, Year, Scenario, X°C rise Scenarioでチェックを付けた項目を取得
    const recurrencePeriodValues = Object.entries(currentCheckedList['recurrencePeriod'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);
    const yearScenarioValues = Object.entries(currentCheckedList['yearScenario'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);
    const riseScenarioValues = Object.entries(currentCheckedList['riseScenario'])
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);

    const checkedItems = [...recurrencePeriodValues, ...yearScenarioValues, ...riseScenarioValues];

    if (props.setLayersList('FinancialHiRes', checkedItems)) {
      currentCheckedList[key] = PanelLists;
      setCheckedList(currentCheckedList);
    } else {
      await props.open();
    }
  };

  // RP（再現期間）パネルの作成
  const makeRecurrencePeriod = () => {
    const lists = checkedList.recurrencePeriod;
    return (
      <>
        <div className={'small fw-bolder mb-1'}>RP（再現期間）</div>
        <div className="d-flex flex-nowrap gap-2">
          {Object.keys(lists).map((key) => {
            return (
              <div className="flex-fill form-check small" key={key}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={key}
                  onChange={(e) => onCheckBox(e, 'recurrencePeriod')}
                  checked={lists[key].checked}
                />
                <label className="form-check-label d-block" htmlFor={key}>
                  {lists[key].value}
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };
  // Year, Scenario, X°C rise Scenarioパネルの作成
  const makeScenario = () => {
    const yearScenarioList = checkedList.yearScenario;
    const riseScenarioList = checkedList.riseScenario;
    return (
      <>
        <div className={'small fw-bolder mb-1'}>Year, Scenario</div>
        {Object.keys(yearScenarioList).map((key) => {
          return (
            <div className="form-check small" key={key}>
              <input
                className="form-check-input"
                type="checkbox"
                id={key}
                onChange={(e) => onCheckBox(e, 'yearScenario')}
                checked={yearScenarioList[key].checked}
              />
              <label className="form-check-label d-block" htmlFor={key}>
                {yearScenarioList[key].value}
              </label>
            </div>
          );
        })}
        <div className={'small fw-bolder mb-1'}>X°C rise Scenario</div>
        {Object.keys(riseScenarioList).map((key) => {
          return (
            <div className="form-check small" key={key}>
              <input
                className="form-check-input"
                type="checkbox"
                id={key}
                onChange={(e) => onCheckBox(e, 'riseScenario')}
                checked={riseScenarioList[key].checked}
              />
              <label className="form-check-label d-block" htmlFor={key}>
                {riseScenarioList[key].value}
              </label>
            </div>
          );
        })}
      </>
    );
  };

  // データレイヤパネルの作成処理
  return (
    <div className="card border-0">
      <div className="badge-plan">Premium</div>
      <div className="card-header bg-transparent border-bottom-0 d-flex flex-column gap-1 mb-0">
        <div className={'d-flex gap-1 align-items-center flex-wrap lh-1 fw-bolder'}>高解像ハザードマップ</div>
        <div className={'d-flex gap-1 align-items-baseline flex-wrap lh-1 fw-bolder'}>High Resolution Hazard map</div>
      </div>

      <div className="card-body pt-0">
        {/* RP（再現期間）パネルの作成 */}
        {makeRecurrencePeriod()}
        <hr className="my-1" />
        {/* Year, Scenarioパネルの作成 */}
        {makeScenario()}
      </div>
    </div>
  );
};

export default PanelFinancialHiRes;
