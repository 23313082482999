import { useEffect, useState, useContext } from 'react';
import { Header, Footer } from '../../layouts/index';
import useMapboxModules from './mapbox/useMapboxModules';

import DataLayerPanel from './components/DataLayerPanel/DataLayerPanel';
import AnalysisSelector from './components/AnalysisSelector/AnalysisSelector';
import LegendPanel from './components/LegendPanel/LegendPanel';

import useMapbox from '../../hooks/useMapbox';
import useApi from '../../hooks/useApi';
import usePermission from '../../hooks/usePermission';
import useServerError from '../../hooks/useServerError';
import { AuthContext } from '../../context/AuthContext';

import useLocationData from './mapbox/useLocationData';
import useLayerData from './mapbox/useLayerData';
import useInfoWindow from './mapbox/useInfoWindow';
import { PageLoader } from '../../views/index';

const HazardMap = () => {
  const { getUserInfo } = useApi();

  const modules = useMapboxModules();
  const { map, LanguageButton } = useMapbox(modules);

  const [userInfo, setUserInfo] = useState(null);

  const locationData = useLocationData();
  const layerData = useLayerData(locationData.locationFeatures);
  const infoWindow = useInfoWindow(locationData.geojson);
  const userCredential = useContext(AuthContext);
  const { isFreeUser } = usePermission();

  const [isInitMap, setIsInitMap] = useState(false);

  const serverError = useServerError();

  // 拠点データ読み込み処理初期化
  useEffect(() => {
    if (!map) return;
    (async () => {
      layerData.init(map);
      locationData.init(map);
      infoWindow.init(map);

      let result;
      try {
        result = await getUserInfo({
          company_id: userCredential.company_id,
          user_permission: userCredential.user_permission
        });
      } catch {
        await serverError.open();
        return;
      }

      setUserInfo(result);
      setIsInitMap(true);
    })();
  }, [map]);

  // エラー発生時
  useEffect(() => {
    if (locationData.serverError) {
      serverError.open();
    }
  }, [locationData.serverError]);

  return (
    <>
      <div id="page-map">
        <Header userInfo={userInfo} />
        <main className="main">
          {/* Mapboxマップの表示要素 */}
          <div className="mapboxgl-map" id="map" />
          {isInitMap && (
            <>
              {/* データレイヤーパネル */}
              <DataLayerPanel
                setVisible={locationData.setVisible}
                setDispLayerList={layerData.setDispLayerList}
                setFirstHiResLayer={locationData.setFirstHiResLayer}
                userInfo={userInfo}
              />
              {/* 拠点フィルター（拠点を持っているユーザーのみ表示） */}
              {!isFreeUser() && (
                <AnalysisSelector userInfo={userInfo} setDispLocationList={locationData.setDispLocationList} />
              )}
            </>
          )}
          {/* 英語、日本語切り替えボタン */}
          <LanguageButton page={'hazard-map'} />
          {/* 凡例パネル */}
          <LegendPanel />
          <serverError.ServerError />
        </main>
        <Footer />
      </div>
      <locationData.Modal>
        <PageLoader />
      </locationData.Modal>
    </>
  );
};

export default HazardMap;
