import { useEffect, useRef, useState } from "react";
import * as ReactDOM from 'react-dom';
import * as validator from "../../../../utils/validator"

const useAnalysisNameInput = () => {
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useRef(null);
  const inputRef = useRef(null)

  const open = async () => {
    setIsOpen(true);

    return new Promise((res) => {
      resolve.current = res;
    })
  }

  const close = (result) => {
    resolve.current(result);
    setIsOpen(false);
  };

  const Dialog = (props) => {
    const [analysisName, setAnalysisName] = useState(props.analysisName);
    const [errMessage, setErrMessage] = useState('　');

    useEffect(() => {
      // autofocusが効かない対策
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    // Analysis IDテキスト変更時処理
    const handleAnalysisNameChange = (e) => {
      setAnalysisName(e.target.value);
    };

    // キャンセルボタン押下時処理
    const handleCancel = () => {
      close({ status : false, analysisName : '' });
    };

    // OKボタン押下時処理
    const handleOK = () => {
      setErrMessage('　');
      // 入力チェック
      const result = validationCheck();
      if (result.error) {
        setErrMessage(result.message);
        inputRef.current.focus();
        return;
      }
      close({ status : true, analysisName : analysisName.trim() });
    };

    // 入力チェック
    const validationCheck = () => {
      let result;
      // 必須チェック
      result = validator.isRequired(analysisName);
      if (result.error) {
        return result;
      }

      // 禁則文字チェック
      result = validator.forbiddenChars(analysisName);
      if (result.error) {
        return result;
      }
      return result;
    };

    if (!isOpen) return null;

    // TODO:レイアウト調整
    return ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">
            <p>
              <span className="fw-bolder">
                検索条件用の“Analysis ID”について、20文字以内で入力してください。<br/>
                なお、前半の日時を示す部分(“yyyymmddhh”)は自動入力され変更することはできません。<br/>
              </span>
              <span>
                Please input the 'Analysis ID' within 20 characters. <br/>
                The initial part indicating the date and time (“yyyymmddhh”) are automatically set and cannot be changed.<br/>
                *'Analysis ID’ can be used for searching analysis results.
              </span>
            </p>
            <p className="text-center">
              <span className="fw-bolder">Analysis ID = </span>
              <span>yyyymmddhh</span>
              <span>_ </span>
              <input
                type="text"
                id="analysis_name_input"
                className="form-control d-inline-block"
                value={analysisName}
                maxLength="20"
                onChange={handleAnalysisNameChange}
                ref={inputRef}  
              />
            </p>
            <div className="h-25">
              <p className="text-center text-danger">
                {errMessage}
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <a className="btn btn-primary rounded-pill mx-1" tabIndex="-1" onClick={handleCancel}>
              Cancel
            </a>
            <a className="btn btn-primary rounded-pill mx-1 px-4" tabIndex="-1" onClick={handleOK}>
              OK
            </a>
          </div>
        </div>
      </div>,
      document.getElementById('modal-root') // モーダルを表示するDOMノード
    );
    };

  return {
    Dialog, open
  }
}

export default useAnalysisNameInput;
