const FORBIDDEN_CHARS = '\\/*?\'"<>|=+&'

// 必須チェック
export const isRequired = (input) => {
    if (!input || input.trim() === "") {
        const result = { 
            error: true, 
            message: "入力は必須です Input is required" 
        }
        return result;
    }
    return { error: false, message: "" };
};

// 最大文字長チェック
export const maxLength = (input, maxLength) => {
    if (input.length > maxLength) {
        const result = { 
            error: true, 
            message: `${maxLength}文字以内で入力してください Please input within ${maxLength} characters` 
        }
        return result;
    }
    return { error: false, message: "" };
};

// 禁則文字チェック
export const forbiddenChars = (input, forbiddenChars = FORBIDDEN_CHARS) => {
    for (let i = 0; i < forbiddenChars.length; i++) {
        if (input.includes(forbiddenChars[i])) {
            const result = { 
                error: true, 
                message: `"${forbiddenChars[i]}"は許可されない文字です The "${forbiddenChars[i]}" character is not allowed`
            }
            return result;
        }
    }
    return { error: false, message: "" };
};