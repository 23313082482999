import { createContext, useState, useContext } from 'react';

const TokenContext = createContext();

export function useTokenContext() {
  return useContext(TokenContext);
}

export function TokenProvider({ children }) {
  const [check, setCheck] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const value = {
    check,
    setCheck,
    idToken,
    setIdToken,
    accessToken,
    setAccessToken,
  };

  return (
    <TokenContext.Provider value={value}>
      {children}
    </TokenContext.Provider>
  );
}
