import { useEffect, useRef, useState } from 'react';
import useSearch from './useSearch';
import useCheckbox from './useCheckbox';
import useConfirm from '../../../../hooks/useConfirm';

const AnalysisSelector = (props) => {
  const confirm = useConfirm();

  // 項目検索用カスタムフック
  const comboboxRef = useRef(null);
  const [searchQuery, serchCheckbox] = useSearch(comboboxRef);

  // チェック項目取得用カスタムフック
  const [init, comboboxTitle, checkboxHandleEvent, checkboxList, checkSelectAllFlg] = useCheckbox(
    props.setDispLocationList,
    confirm.open
  );

  // パネル開閉
  const [disabledPanel, setDisabledPanel] = useState(true);
  useEffect(() => {
    if (!disabledPanel) return;
    if (!props.userInfo) return;
    if (!props.userInfo.analysis_history) return;

    init(props.userInfo.analysis_history);
    setDisabledPanel(false);
  }, [props.userInfo]);

  // パネル開閉
  const [showPanel, setShowPanel] = useState(true);
  const panelToggle = () => {
    setShowPanel(!showPanel);
  };

  const panelHtml = disabledPanel ? (
    <></>
  ) : (
    <>
      <div className="panel panel-top-right panel-analysis-id">
        <div className={'combobox' + (showPanel ? '' : ' active')}>
          <div className="combobox-title" onClick={panelToggle}>
            {comboboxTitle}
          </div>
          <div className="combobox-dropdpwn">
            <div className="combobox-search">
              <input
                type="text"
                className="form-control form-control-sm"
                value={searchQuery}
                onChange={serchCheckbox}
              />
            </div>
            <ul className="combobox-list" ref={comboboxRef}>
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input combobox-all"
                    type="checkbox"
                    value=""
                    id="combobox-0"
                    onChange={checkboxHandleEvent.toggleCheckAll}
                    checked={checkSelectAllFlg}
                  />
                  <label className="form-check-label d-block" htmlFor="combobox-0">
                    (Select All)
                  </label>
                </div>
              </li>
              {checkboxList.length > 0 &&
                checkboxList.map((checkbox) => {
                  return (
                    <li key={checkbox.id} className="check-list-item">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={checkbox.id}
                          checked={checkbox.checked}
                          onChange={checkboxHandleEvent.checkboxClick}
                        />
                        <label className="form-check-label d-block" htmlFor={checkbox.id}>
                          {checkbox.id}
                        </label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <confirm.Confirm yes="Yes" no="No">
        <div>
          <p className="text-center">
            マップ上に表示する拠点の件数が多く、表示に時間がかかります。マップ上に拠点を表示してもよいでしょうか。
            <br />
            There are a large number of locations to display, and it may take some time. <br />
            Do you want to continue displaying the locations on the map?
          </p>
        </div>
      </confirm.Confirm>
    </>
  );

  return panelHtml;
};

export default AnalysisSelector;
