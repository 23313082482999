import { useContext } from "react";
import useConfirm from "../../../../hooks/useConfirm";
import { AuthContext } from "../../../../context/AuthContext";
import usePermission from "../../../../hooks/usePermission";
import useAnalysisNameInput from "./useAnalysisNameInput";

/**
 * データ入力画面：購入確認
 */
function ConfirmPurchase(props) {

  const userCredential = useContext(AuthContext);

  const { isFinancialUser } = usePermission();

  const confirm = useConfirm();
  const analysisNameInput = useAnalysisNameInput();

  // 購入確認
  const handleClickConfirmPurchase = async () => {

    let analysisName = userCredential.family_name;

    // 購入確認ダイアログ
    if(isFinancialUser()) {
      // 金融ユーザーはAnalysis ID用文字列入力ダイアログ
      const result = await analysisNameInput.open();
      if(!result.status) {
        return;
      }
      analysisName = result.analysisName;
    } else {
      // 一般ユーザーはGDPRダイアログ
      if(!(await confirm.open())) {
        return;
      }
    }

    props.onAnalyze({ analysisName });
  };

  // 分析実行ボタン
  const createAnalysisButton = () => {
    // 金融版ユーザー：分析開始、一般ユーザー：購入確認
    let buttonText = isFinancialUser() ?
        <><span className="icon icon-analysis"></span>分析開始<span className="ms-2">(Run Analysis)</span></> :
        <><span className="icon icon-cart"></span>購入確認<span className="ms-2">(Confirm Purchase)</span></>
    
    let buttonHtml = <></>

    if(isFinancialUser()){
      if(props.uploadSyncFlg || (!!props.countAddedLocation && props.countAddedLocation > 0)){
        buttonHtml = 
          <a className="btn btn-lg btn-primary rounded-pill pe-4" onClick={handleClickConfirmPurchase}>
            {buttonText}
          </a>
      } else {
        buttonHtml = 
          <a className="btn btn-lg btn-primary rounded-pill pe-4 disabled" tabIndex="-1">
            {buttonText}
          </a>
      }
    } else {
      if(props.locations && props.locations.length > 0){
        buttonHtml = 
        <a className="btn btn-lg btn-primary rounded-pill pe-4" onClick={handleClickConfirmPurchase}>
          {buttonText}
        </a>
      } else {
        buttonHtml = 
          <a className="btn btn-lg btn-primary rounded-pill pe-4 disabled" tabIndex="-1">
            {buttonText}
          </a>
      }
    }
    return buttonHtml
  };

  return (
    <>
      <div className="sticky-buttons mb-0">
        {createAnalysisButton()}
      </div>
      <confirm.Confirm yes="同意する Agree" no="Cancel">
        <div>
          <p className="text-center">
            <a href={`${process.env.REACT_APP_PURCHASE_LOCATION_PRIVACY_POLICY}`} target="_blank" rel="noopener noreferrer">
              個人情報保護規則
            </a>
            <br/>
            <a href={`${process.env.REACT_APP_PURCHASE_LOCATION_PRIVACY_POLICY_EN}`} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </p>
          <p className="text-center">
            <a href={`${process.env.REACT_APP_PURCHASE_LOCATION_SITE_POLICY}`} target="_blank" rel="noopener noreferrer">
              利用規約
            </a>
            <br/>
            <a href={`${process.env.REACT_APP_PURCHASE_LOCATION_SITE_POLICY_EN}`} target="_blank" rel="noopener noreferrer">
              Term of Service
            </a>
          </p>
          <p className="text-center">
            上記内容について確認しました。<br/>
            I have confirmed the above.
          </p>
          <p className="text-center">
            入力情報で購入をリクエストします。<br/>
            I will request import location data.
          </p>
        </div>
      </confirm.Confirm>
      <analysisNameInput.Dialog analysisName={userCredential.family_name} />
    </>
  );
}

export default ConfirmPurchase;
