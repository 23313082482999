import { useState, useRef, useEffect } from 'react';

import usePermission from './../../../hooks/usePermission.js';
import useTilesets from './useTilesets.js';

const useLayerData = (locationFeatures) => {
  const map = useRef(null);
  const isFirstLoaded = useRef(false);

  const { isFinancialUser } = usePermission();

  const [dispLayerList, setDispLayerList] = useState([]);
  const [locationsGeojson, setLocationsGeojson] = useState([]);

  const {
    createHazardMap,
    createHazardMapLoRes1,
    createHazardMapLoRes2,
    createFloodPrediction,
    createHazardMapHiRes,
    createLocationHiresSource,
    createLocationHiresLayer,
    createLocationLayer
  } = useTilesets();

  useEffect(() => {
    if (!map.current) return;

    if (!isFirstLoaded.current) {
      map.current.once('load', () => {
        showLayer();
        isFirstLoaded.current = true;
      });
    } else {
      showLayer();
    }
  }, [dispLayerList, map.current, locationsGeojson]);

  useEffect(() => {
    setLocationsGeojson([...locationsGeojson, ...locationFeatures]);
  }, [locationFeatures]);

  // 初期化
  const init = (_map) => {
    map.current = _map;

    const hazardMap = createHazardMap().object;
    const hazardMapLoRes1 = createHazardMapLoRes1().object;
    const hazardMapLoRes2 = createHazardMapLoRes2().object;
    const floodPrediction = createFloodPrediction().object;
    const hazardMapHiRes = createHazardMapHiRes().objects;

    // 地図にタイルセットを追加
    map.current.once('load', () => {
      // ハザードマップタイルセット
      map.current.addSource(hazardMap.sourceId, hazardMap.source);
      for (const layer of hazardMap.layer) {
        map.current.addLayer(layer);
      }

      // 2km meshハザードマップタイルセット
      // 無償/有償ユーザーのハザードマップ：2020 + 金融ユーザーの高解像度ハザードマップ16パターン
      map.current.addSource(hazardMapLoRes1.sourceId, hazardMapLoRes1.source);
      for (const layer of hazardMapLoRes1.layer) {
        map.current.addLayer(layer);
      }
      // 無償/有償ユーザーのハザードマップ：2080, SSP5-8.5
      map.current.addSource(hazardMapLoRes2.sourceId, hazardMapLoRes2.source);
      for (const layer of hazardMapLoRes2.layer) {
        map.current.addLayer(layer);
      }

      // 洪水頻度変化予測タイルセット
      map.current.addSource(floodPrediction.sourceId, floodPrediction.source);
      for (const layer of floodPrediction.layer) {
        map.current.addLayer(layer);
      }

      // 高解像度ハザードマップタイルセット(14タイルセット)
      for (const tileset of hazardMapHiRes) {
        map.current.addSource(tileset.sourceId, tileset.source);
        for (const layer of tileset.layer) {
          map.current.addLayer(layer);
        }
      }
    });
  };

  const showLayer = () => {
    // 各レイヤー設定情報を取得
    const hazardMapConfig = createHazardMap();
    const hazardMapLoRes1Config = createHazardMapLoRes1();
    const hazardMapLoRes2Config = createHazardMapLoRes2();
    const floodPredictionConfig = createFloodPrediction();
    const hazardMapHiResConfig = createHazardMapHiRes();

    // 表示レイヤーの初期化
    const layerIds = [
      ...hazardMapConfig.layerIds,
      ...floodPredictionConfig.layerIds,
      ...hazardMapLoRes1Config.layerIds,
      ...hazardMapLoRes2Config.layerIds
    ];

    for (const layerId of layerIds) {
      map.current.setLayoutProperty(layerId, 'visibility', 'none');
    }

    // 高解像度ハザードマップ用ソース更新
    if (!isFinancialUser()) {
      // 拠点周辺の90m mesh用Sourceを削除→追加して更新
      const locationHiresSource = createLocationHiresSource(locationsGeojson);
      if (map.current.getSource(locationHiresSource.sourceId)) {
        // mapに追加されているレイヤーを削除
        const layers = map.current.getStyle().layers;
        const hiresLayersId = layers
          .filter((item) => item.id.startsWith(hazardMapHiResConfig.layerIdPrefix))
          .map((item) => item.id);
        for (const layerId of hiresLayersId) {
          map.current.removeLayer(layerId);
        }
        map.current.removeSource(locationHiresSource.sourceId);
      }
      map.current.addSource(locationHiresSource.sourceId, locationHiresSource.source);
    } else {
      for (const layerId of hazardMapHiResConfig.layerIds) {
        map.current.setLayoutProperty(layerId, 'visibility', 'none');
      }
    }

    // 左パネルでチェックされているレイヤーを表示
    for (const dispLayer of dispLayerList) {
      // チェックされたレイヤーの種類を取得
      const underscoreIndex = dispLayer.indexOf('_');
      const tilesetType = dispLayer.substring(0, underscoreIndex);

      // タイルセットのプロパティを取得
      const underscoreLastIndex = dispLayer.lastIndexOf('_');
      const tilesetColumn = dispLayer.substring(underscoreLastIndex + 1);

      if (tilesetType === 'HazardMap') {
        // ハザードマップレイヤー表示
        map.current.setLayoutProperty(hazardMapConfig.layerIdPrefix + tilesetColumn, 'visibility', 'visible');

        // ハザードマップレイヤー表示を契機に2km meshハザードマップレイヤーを表示する
        if (!isFinancialUser()) {
          // 無償/有償ユーザーのハザードマップ：2020
          if (tilesetColumn === '0')
            map.current.setLayoutProperty(hazardMapLoRes1Config.layerIdPrefix + tilesetColumn, 'visibility', 'visible');

          // 無償/有償ユーザーのハザードマップ：2080, SSP5-8.5
          if (tilesetColumn === '8')
            map.current.setLayoutProperty(hazardMapLoRes2Config.layerIdPrefix + tilesetColumn, 'visibility', 'visible');
        } else {
          // 金融ユーザーの高解像度ハザードマップ16パターン
          map.current.setLayoutProperty(hazardMapLoRes1Config.layerIdPrefix + tilesetColumn, 'visibility', 'visible');
        }
      } else if (tilesetType === 'FloodPrediction') {
        // 洪水頻度変化予測タイルセット表示
        map.current.setLayoutProperty(floodPredictionConfig.layerIdPrefix + tilesetColumn, 'visibility', 'visible');
      } else if (tilesetType === 'HazardMapHiRes') {
        // 高解像度ハザードマップタイルセット表示
        if (!isFinancialUser()) {
          map.current.addLayer(createLocationHiresLayer(tilesetColumn));
        } else {
          for (let i = 1; i <= hazardMapHiResConfig.objects.length; i++) {
            map.current.setLayoutProperty(
              hazardMapHiResConfig.layerIdPrefix + i + '_' + tilesetColumn,
              'visibility',
              'visible'
            );
          }
        }
      }
    }

    if (map.current.getLayer(createLocationLayer().layerId)) {
      // 拠点レイヤーを一番上に移動する
      map.current.moveLayer(createLocationLayer().layerId);
    }
  };

  return {
    init,
    setDispLayerList
  };
};

export default useLayerData;
