import { useRef } from 'react';
import useApi from '../../../../hooks/useApi';
import usePermission from '../../../../hooks/usePermission';

/**
 * データ入力画面：拠点ファイルアップロード
 */
function UploadLocation(props) {
  const { isFinancialUser } = usePermission();
  const fileName = isFinancialUser() ? 'template_import_annual.csv' : 'template_import.csv';

  const { generateSignedUrl } = useApi();

  const fileInput = useRef(null);

  // ダウンロードリンク押下
  const handleClickDownload = async () => {
    try {
      // 署名付きURLの発行
      const result = await generateSignedUrl(fileName);

      // ダウンロードリンクを生成
      const a = document.createElement('a');
      a.href = result.signed_url;
      a.download = fileName;

      // リンクをクリックしてダウンロードを開始
      a.click();
    } catch {}
  };

  // アップロードボタン押下
  const handleClickUpload = () => {
    fileInput.current.click();
  };

  // アップロードファイル選択後
  const handleChangeFile = async (e) => {
    const csv = e.target.files[0];

    // 同一ファイル名で再アップロード可能にする
    fileInput.current.value = '';

    props.onChange({ csv });
  };

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-9">
            <div className="mb-2">
              <p className="mb-1">
                <span className="fw-bolder">分析対象拠点のファイルをアップロードしてください。</span>
                {isFinancialUser() ? (
                  <span>Please upload a file containing the location information.</span>
                ) : (
                  <span className="fw-bolder">アップロードした拠点周辺のプレミアムハザードマップを購入できます。</span>
                )}
              </p>
              {!isFinancialUser() && (
                <p className="mb-1">
                  Please upload a file containing the location information. You can purchase the Premium Hazardmap
                  around each location.
                </p>
              )}
            </div>
            <a className="mb-1" href="#" onClick={handleClickDownload}>
              <img
                className="me-1"
                src={`${process.env.PUBLIC_URL}/img/icon_csv_lg.png`}
                width="25"
                height="25"
                alt="CSV"
              />
              <span className="fw-bolder">アップロード用のファイルはこちらからダウンロードできます。</span>
              <span>You can download the template here.</span>
            </a>
          </div>
          <div className="col-3">
            <div className="h-100 text-end">
              <button type="button" className="btn btn-lg btn-primary rounded-pill pe-4" onClick={handleClickUpload}>
                <span className="icon icon-upload"></span>Upload location file
              </button>
              <input
                type="file"
                className="form-control d-none"
                id="file_upload"
                accept="text/csv"
                ref={fileInput}
                onChange={handleChangeFile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadLocation;
