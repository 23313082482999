import { useRef, useState } from "react";
import * as ReactDOM from 'react-dom';

const useServerError = () => {
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useRef(null);

  const open = async (content) => {
    setIsOpen(true);

    return new Promise((res) => {
      resolve.current = res;
    })
  }

  const close = (result) => {
    resolve.current(result);
    setIsOpen(false);
  };

  const ServerError = () => isOpen && 
    ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">
            <p>
              処理中にエラーが発生しました。お手数ですが下記 "contact" よりご連絡ください<br/>
              An error occurred during processing. We apologize for the inconvenience, please contact us using the "contact" below
            </p>
          </div>
          <div className="modal-footer">
            <a className="btn btn-primary rounded-pill mx-1" tabIndex="-1" onClick={() => close(true)}>
              OK
            </a>
          </div>
        </div>
      </div>,
    document.getElementById('modal-root') // モーダルを表示するDOMノード
  );

  return {
    ServerError, open
  }
}

export default useServerError;