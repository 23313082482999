/**
 * 処理をスリープさせる。
 * @param {*} millis ミリ秒
 * @returns Promise - 指定ミリ秒経過後resolveされる
 */
export const sleep = async (millis) => {
  return new Promise(resolve => setTimeout(resolve, millis));
}

/**
 * Mapboxに画像を読み込む。
 * @param {*} map マップ
 * @param {*} url 画像URL
 * @returns Promise - 画像読み込み後resolveされる
 */
export const loadImageAsPromise = (map, url) => {
  return new Promise((resolve, reject) => {
    map.loadImage(url, (error, image) => {
      if (image != null) {
        resolve(image);
      } else {
        reject(error);
      }
    });
  });
};