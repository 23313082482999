import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useEffect } from 'react';
import { PageLoader } from '../views/index';
import { useTokenContext } from '../context/TokenContext';

export const AuthenticationGuard = ({ component }) => {

  const redirectingLoader = () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    );

  const myComponentStyle = {
     margin: '2em auto',
     textAlign: 'center',
     fontSize: '2em',
  };
  const redirectingTokenError = () => (
      <div style={myComponentStyle}>
        TokenError
      </div>
    );

  const apiUrl = process.env.REACT_APP_API_URL;

  const withTokenCheck = (Component) => {
    return function WithTokenCheck(props) {
      const { getAccessTokenSilently, user } = useAuth0();
      const { check, setCheck, setIdToken, accessToken, setAccessToken } = useTokenContext();

      useEffect(() => {
        setIdToken(user);
        if (check) {
          return;
        }
        (async () => {
          try {
              const encodedToken = await getAccessTokenSilently();
              const method = "POST";
              const body = JSON.stringify({encoded_jwt: encodedToken});
              const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              };
              const response = await fetch(`${apiUrl}jwt_decode`, {method, headers, body});
              if(response.ok) {
                  const responseData = await response.json();
                  setAccessToken(responseData);
              } else {
                  setAccessToken(null);
              }
          } catch (error) {
              setAccessToken(null);
          }
          setCheck(true);
        })();
      }, [
        getAccessTokenSilently,
        user, setIdToken,
        check, setCheck, accessToken, setAccessToken,
      ]);

      return !check ? redirectingLoader() : ((!!accessToken) ? <Component {...props} /> : redirectingTokenError());
    };
  };

  const tokenCheckComponent = withTokenCheck(component);

  const Component = withAuthenticationRequired(tokenCheckComponent, {
    onRedirecting: redirectingLoader,
  });

  return <Component />;
};
