function Description() {
  return (
    <div className="mb-3">
      <p className="fw-bolder mb-1">
        分析結果を表示する対象を選択してください。（一回の分析結果表示は10件までとなります。）
      </p>
      <p className="mb-1">Please select analysis results. (Up to 10 analysis results can be displayed at one time.)</p>
    </div>
  );
}

export default Description;
