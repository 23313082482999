import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

/**
 * 権限関連Hooks
 * @returns
 */
const usePermission = () => {
  const userCredential = useContext(AuthContext);

  // 無償ユーザー
  const isFreeUser = () => userCredential.user_permission === '0';

  // 有償ユーザー
  const isPremiumUser = () => userCredential.user_permission === '1';

  // 金融ユーザー
  const isFinancialUser = () => userCredential.user_permission === '2';

  // 管理者ユーザー
  const isAdminUser = () => userCredential.admin_permission === '1';

  // データ入力画面へのアクセス権限
  const hasAccessToPurchaseLocation = () => {
    return isFreeUser() || (isPremiumUser() && isAdminUser()) || (isFinancialUser() && isAdminUser());
  };

  return {
    isFreeUser,
    isPremiumUser,
    isFinancialUser,
    isAdminUser,
    hasAccessToPurchaseLocation
  };
};

export default usePermission;
