import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthContext';
import useApi from '../../../../hooks/useApi';

function Download(props) {

  // 認証情報
  const userCredential = useContext(AuthContext);

  const {downloadCsv, downloadPdf} = useApi();

  const handleDownloadPdf = async () => {
    props.openPageLoader(true);
    try {
      await downloadPdf({
        "user_permission": `${userCredential.user_permission}`,
        "company_id": userCredential.company_id,
        "scenario": props.scenario,
        "analysis_id_list": props.analysisIds.current,
        "location_name_list": props.locationNames.current,
        "group_id_list": props.groupIds.current,
        "user_id": `${userCredential.user_id}`
      });
    } catch {
      props.openPageLoader(false);
      await props.openServerError();
      return;  
    }
    props.openPageLoader(false);
  };

  const handleClickDownloadCsv = async () => {
    // 3ファイル出力
    if (!await handleDownloadCsv('depth')) return;
    if (!await handleDownloadCsv('damage')) return;
    if (!await handleDownloadCsv('affected_day')) return;
  };

  const handleDownloadCsv = async (download_result) => {
    props.openPageLoader(true);
    try {
      await downloadCsv({
        "user_permission": `${userCredential.user_permission}`,
        "company_id": userCredential.company_id,
        "analysis_id_list": props.analysisIds.current,
        "location_name_list": props.locationNames.current,
        "group_id_list": props.groupIds.current,
        "user_id": `${userCredential.user_id}`,
        "download_result": download_result
      });
    } catch(error) {
      props.openPageLoader(false);
      if (typeof error === 'string') {
        await props.openError(error);
      } else {
        await props.openServerError();
      }
      return false;  
    }
    props.openPageLoader(false);
    return true;
  };

  return (
    <div className="sticky-buttons">
      <button className="btn btn-lg btn-primary rounded-pill pe-4" onClick={handleDownloadPdf}>
        <span className="icon icon-pdf"></span>Download PDF
      </button>
      <button className="btn btn-lg btn-primary rounded-pill pe-4" onClick={handleClickDownloadCsv}>
        <span className="icon icon-csv"></span>Download CSV
      </button>
    </div>
  );
}

export default Download;
