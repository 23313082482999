import { useState } from "react";
import * as ReactDOM from 'react-dom';

const useIndicator = () => {
  const [isLoading, setIsLoading] = useState(false);

  const loading = (loadingFlg) => {
    setIsLoading(loadingFlg);
  }

  const Indicator = (props) => isLoading && 
    ReactDOM.createPortal(
      <div className="modal-overlay indicator">
        <div className="modal">
          <div className="modal-content">
            {props.children}
          </div>
        </div>
      </div>,
    document.getElementById('modal-root') // モーダルを表示するDOMノード
  );

  return {
    Indicator, loading
  }
}

export default useIndicator;