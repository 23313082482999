import { useEffect, useState, useRef } from 'react';

import PanelItems from './PanelItems.js';
import usePermission from './../../../../hooks/usePermission.js';

const useSelectLayer = (setFirstHiResLayer) => {
  // 地図上に表示するレイヤーID
  const [hazardMapLayer, setHazardMapLayer] = useState([]);
  const [floodPredictionLayer, setFloodPredictionLayer] = useState([]);
  const [hazardMapHiResLayer, setHazardMapHiResLayer] = useState([]);
  const [financialHiResLayer, setFinancialHiResLayer] = useState([]);
  const { isFinancialUser } = usePermission();

  const firstHiResLayerFlg = useRef(false);

  // 表示するレイヤー一覧
  const [dispLayer, setDispLayer] = useState([]);

  useEffect(() => {
    setDispLayer([...hazardMapLayer, ...floodPredictionLayer, ...hazardMapHiResLayer, ...financialHiResLayer]);
  }, [hazardMapLayer, floodPredictionLayer, hazardMapHiResLayer, financialHiResLayer]);

  useEffect(() => {
    firstHiResLayerFlg.current = false;
  }, [hazardMapHiResLayer, financialHiResLayer]);

  // 表示対象のレイヤーのリスト作成処理
  const setLayersList = (panelType, value) => {
    let currentLayers = [];
    let totalLayer = 0;
    const layerLimit = 5;

    // ハザードマップ
    if (panelType === 'HazardMap') {
      const panelItem = PanelItems.HazardMap.yearScenario;

      // 現在気候再現期間100年ハザードマップ
      if (value.includes(panelItem.HazardMap1_1.value)) {
        currentLayers.push('HazardMap_0');
      }
      // 現在気候再現期間1000年ハザードマップ
      if (value.includes(panelItem.HazardMap1_2.value)) {
        currentLayers.push('HazardMap_8');
      }
      totalLayer =
        floodPredictionLayer.length + hazardMapHiResLayer.length + currentLayers.length;
    }

    // 洪水頻度変化予測
    if (panelType === 'FloodPrediction') {
      const panelItem = PanelItems.FloodPrediction.yearScenario;

      // 現在気候再現期間100年ハザードマップ
      if (value.includes(panelItem.FloodPrediction1_1.value)) {
        currentLayers.push('FloodPrediction_0');
      }
      // 現在気候再現期間1000年ハザードマップ
      if (value.includes(panelItem.FloodPrediction1_2.value)) {
        currentLayers.push('FloodPrediction_1');
      }

      if (isFinancialUser()) {
        totalLayer = financialHiResLayer.length / 2 + currentLayers.length;
      } else {
        totalLayer = hazardMapLayer.length + hazardMapHiResLayer.length + currentLayers.length;
      }
    }

    // 高解像度ハザードマップ
    if (panelType === 'HazardMapHiRes') {
      const recurrencePeriod = PanelItems.HazardMapHiRes.recurrencePeriod;
      const yearScenario = PanelItems.HazardMapHiRes.yearScenario;

      // 現在気候再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_1.value) &&
        value.includes(yearScenario.HazardMapHiRes2_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_0');
      }
      // 現在気候再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_2.value) &&
        value.includes(yearScenario.HazardMapHiRes2_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_1');
      }
      // 将来気候(ssp126,2050)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_1.value) &&
        value.includes(yearScenario.HazardMapHiRes2_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_2');
      }
      // 将来気候(ssp126,2050)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_2.value) &&
        value.includes(yearScenario.HazardMapHiRes2_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_3');
      }
      // 将来気候(ssp585,2050)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_1.value) &&
        value.includes(yearScenario.HazardMapHiRes2_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_6');
      }
      // 将来気候(ssp585,2050)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_2.value) &&
        value.includes(yearScenario.HazardMapHiRes2_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_7');
      }
      // 将来気候(ssp126,2080)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_1.value) &&
        value.includes(yearScenario.HazardMapHiRes2_4.value)
      ) {
        currentLayers.push('HazardMapHiRes_4');
      }
      // 将来気候(ssp126,2080)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_2.value) &&
        value.includes(yearScenario.HazardMapHiRes2_4.value)
      ) {
        currentLayers.push('HazardMapHiRes_5');
      }
      // 将来気候(ssp585,2080)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_1.value) &&
        value.includes(yearScenario.HazardMapHiRes2_5.value)
      ) {
        currentLayers.push('HazardMapHiRes_8');
      }
      // 将来気候(ssp585,2080)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.HazardMapHiRes1_2.value) &&
        value.includes(yearScenario.HazardMapHiRes2_5.value)
      ) {
        currentLayers.push('HazardMapHiRes_9');
      }
      totalLayer =
        hazardMapLayer.length + floodPredictionLayer.length + currentLayers.length;
    }

    // 金融版高解像度ハザードマップ
    if (panelType === 'FinancialHiRes') {
      const recurrencePeriod = PanelItems.FinancialHiRes.recurrencePeriod;
      const yearScenario = PanelItems.FinancialHiRes.yearScenario;
      const riseScenario = PanelItems.FinancialHiRes.riseScenario;

      // 現在気候再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(yearScenario.FinancialHiRes2_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_0');
        currentLayers.push('HazardMap_0');
      }
      // 現在気候再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(yearScenario.FinancialHiRes2_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_1');
        currentLayers.push('HazardMap_1');
      }
      // 将来気候(ssp126,2050)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(yearScenario.FinancialHiRes2_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_2');
        currentLayers.push('HazardMap_2');
      }
      // 将来気候(ssp126,2050)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(yearScenario.FinancialHiRes2_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_3');
        currentLayers.push('HazardMap_3');
      }
      // 将来気候(ssp585,2050)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(yearScenario.FinancialHiRes2_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_6');
        currentLayers.push('HazardMap_6');
      }
      // 将来気候(ssp585,2050)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(yearScenario.FinancialHiRes2_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_7');
        currentLayers.push('HazardMap_7');
      }
      // 将来気候(ssp126,2080)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(yearScenario.FinancialHiRes2_4.value)
      ) {
        currentLayers.push('HazardMapHiRes_4');
        currentLayers.push('HazardMap_4');
      }
      // 将来気候(ssp126,2080)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(yearScenario.FinancialHiRes2_4.value)
      ) {
        currentLayers.push('HazardMapHiRes_5');
        currentLayers.push('HazardMap_5');
      }
      // 将来気候(ssp585,2080)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(yearScenario.FinancialHiRes2_5.value)
      ) {
        currentLayers.push('HazardMapHiRes_8');
        currentLayers.push('HazardMap_8');
      }
      // 将来気候(ssp585,2080)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(yearScenario.FinancialHiRes2_5.value)
      ) {
        currentLayers.push('HazardMapHiRes_9');
        currentLayers.push('HazardMap_9');
      }
      // 将来気候(2.0度上昇)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(riseScenario.FinancialHiRes3_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_10');
        currentLayers.push('HazardMap_10');
      }
      // 将来気候(2.0度上昇)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(riseScenario.FinancialHiRes3_1.value)
      ) {
        currentLayers.push('HazardMapHiRes_11');
        currentLayers.push('HazardMap_11');
      }
      // 将来気候(3.0度上昇)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(riseScenario.FinancialHiRes3_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_12');
        currentLayers.push('HazardMap_12');
      }
      // 将来気候(3.0度上昇)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(riseScenario.FinancialHiRes3_2.value)
      ) {
        currentLayers.push('HazardMapHiRes_13');
        currentLayers.push('HazardMap_13');
      }
      // 将来気候(4.0度上昇)再現期間100年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_1.value) &&
        value.includes(riseScenario.FinancialHiRes3_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_14');
        currentLayers.push('HazardMap_14');
      }
      // 将来気候(4.0度上昇)再現期間1000年ハザードマップ
      if (
        value.includes(recurrencePeriod.FinancialHiRes1_2.value) &&
        value.includes(riseScenario.FinancialHiRes3_3.value)
      ) {
        currentLayers.push('HazardMapHiRes_15');
        currentLayers.push('HazardMap_15');
      }
      totalLayer = floodPredictionLayer.length + currentLayers.length / 2;
    }

    if (totalLayer > layerLimit) return false;

    if (panelType === 'HazardMap') {
      setHazardMapLayer(currentLayers);
    } else if (panelType === 'FloodPrediction') {
      setFloodPredictionLayer(currentLayers);
    } else if (panelType === 'HazardMapHiRes') {
      setHazardMapHiResLayer(currentLayers);
    } else if (panelType === 'FinancialHiRes') {
      setFinancialHiResLayer(currentLayers);
    }

    return true;
  };

  // 拠点クリック時、表示する高解像度ハザードマップが存在しない場合にレイヤーを自動選択
  const firstHiResLayer = () => {
    if (!isFinancialUser()) {
      if (hazardMapHiResLayer.length) return;

      setHazardMapHiResLayer(['HazardMapHiRes_0']);
    } else {
      if (financialHiResLayer.length) return;

      const layer = ['HazardMap_0', 'HazardMapHiRes_0'];
      setFinancialHiResLayer(layer);
    }
    firstHiResLayerFlg.current = true;
  };

  useEffect(() => {
    setFirstHiResLayer(() => firstHiResLayer);
  }, [hazardMapHiResLayer, financialHiResLayer]);

  return [dispLayer, setLayersList, firstHiResLayerFlg.current];
};

export default useSelectLayer;
