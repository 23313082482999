import mapboxgl from 'mapbox-gl';
import { useState, useRef } from 'react';

const useMapboxModules = () => {
  const initOnceFlg = useRef(false);
  const [modules, setModules] = useState({});

  if (!initOnceFlg.current) {
    const modules = {};

    // 検索ボックス
    modules.mapboxGeocoder = {
      options: {
        accessToken: process.env.REACT_APP_MAPBOX_ACCESSTOKEN,
        reverseGeocode: true,
        mapboxgl: mapboxgl
      }
    };

    // 現在地移動
    modules.geolocateControl = {
      options: {
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true,
        showAccuracyCircle: false
      }
    };

    // ズームイン／ズームアウトボタン
    modules.navigationControl = {
      options: {
        showCompass: false
      },
      position: 'top-right'
    };

    // 縮尺
    modules.scaleControl = {
      options: {
        maxWidth: 150,
        unit: 'metric'
      },
      position: 'bottom-right'
    };

    // 帰属表示
    modules.attributionControl = {
      position: 'bottom-right'
    };

    // 言語切替
    modules.initLanguage = (map) => {
      map.once('load', () => {
        map.getStyle().layers.forEach(function (thisLayer) {
          if (thisLayer.id.indexOf('-label') > 0) {
            map.setLayoutProperty(thisLayer.id, 'text-field', ['get', 'name_ja']);
          }
        });
      });
    };

    setModules(modules);

    initOnceFlg.current = true;
  }
  return { ...modules };
};

export default useMapboxModules;
