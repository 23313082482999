import usePermission from '../../../hooks/usePermission';

const useTilesets = () => {
  const { isFinancialUser } = usePermission();

  // 金融版ハザードマップレイヤー追加順（ssp585,2050 > ssp126,2080とする）
  const hazardMapLayerOrder = [0, 1, 2, 3, 6, 7, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15];

  // ハザードマップ（500m mesh）
  const createHazardMap = () => {
    const layerIdPrefix = 'Hmap-15sec_globe_2_';
    const layerIds = [];
    const object = {
      sourceId: 'Hmap-15sec_globe_2',
      source: {
        type: 'vector',
        url: 'mapbox://interrisk.Hmap-15sec_globe_2'
      },
      layer: []
    };

    // 一般版のレイヤー設定
    if (!isFinancialUser()) {
      const tilesetNumbers = [0, 8];

      for (const tilesetNumber of tilesetNumbers) {
        const numberString = tilesetNumber.toString();
        object.layer.push({
          'id': `${layerIdPrefix}${numberString}`,
          'layout': {
            'visibility': 'none'
          },
          'type': 'fill',
          'source': 'Hmap-15sec_globe_2',
          'source-layer': 'Hmap-15sec_globe_2',
          'minzoom': 9,
          'filter': ['all', ['has', numberString], ['!=', ['get', numberString], 0]],
          'paint': {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', numberString],
              0,
              'rgb(201, 215, 244)',
              10,
              'rgb(0, 68, 205)'
            ],
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 0, 0, 0)'
          }
        });
        layerIds.push(`${layerIdPrefix}${numberString}`);
      }
    } else {
      // 金融版のレイヤー設定
      for (let tilesetNumber = 0; tilesetNumber <= 15; tilesetNumber++) {
        const numberString = hazardMapLayerOrder[tilesetNumber].toString();
        object.layer.push({
          'id': `${layerIdPrefix}${numberString}`,
          'layout': {
            'visibility': 'none'
          },
          'type': 'fill',
          'source': 'Hmap-15sec_globe_2',
          'source-layer': 'Hmap-15sec_globe_2',
          'minzoom': 9,
          'maxzoom': 13,
          'filter': ['all', ['has', numberString], ['!=', ['get', numberString], 0]],
          'paint': {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', numberString],
              0,
              'rgb(201, 215, 244)',
              10,
              'rgb(0, 68, 205)'
            ],
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 0, 0, 0)'
          }
        });
        layerIds.push(`${layerIdPrefix}${numberString}`);
      }
    }
    return { object, layerIds, layerIdPrefix };
  };

  // ハザードマップ（2km mesh）Hmap-60sec_globe_1
  const createHazardMapLoRes1 = () => {
    const layerIdPrefix = 'Hmap-60sec_globe_1_';
    const layerIds = [];
    const object = {
      sourceId: 'Hmap-60sec_globe_1',
      source: {
        type: 'vector',
        url: 'mapbox://interrisk.Hmap-60sec_globe_1'
      },
      layer: []
    };

    // 一般版のレイヤー設定
    if (!isFinancialUser()) {
      const tilesetNumbers = [0];

      for (const tilesetNumber of tilesetNumbers) {
        const numberString = tilesetNumber.toString();
        object.layer.push({
          'id': 'Hmap-60sec_globe_1_' + numberString,
          'layout': {
            'visibility': 'none'
          },
          'type': 'fill',
          'source': 'Hmap-60sec_globe_1',
          'source-layer': 'Hmap-60sec_globe_1',
          'minzoom': 4,
          'maxzoom': 9,
          'filter': ['all', ['has', 'c'], ['!=', ['get', 'c'], 0]],
          'paint': {
            'fill-color': ['interpolate', ['linear'], ['get', 'c'], 0, 'rgb(201, 215, 244)', 10, 'rgb(0, 68, 205)'],
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 0, 0, 0)'
          }
        });
        layerIds.push('Hmap-60sec_globe_1_' + numberString);
      }
    } else {
      // 金融版のレイヤー設定
      for (let tilesetNumber = 0; tilesetNumber <= 15; tilesetNumber++) {
        const numberString = hazardMapLayerOrder[tilesetNumber].toString();
        object.layer.push({
          'id': 'Hmap-60sec_globe_1_' + numberString,
          'layout': {
            'visibility': 'none'
          },
          'type': 'fill',
          'source': 'Hmap-60sec_globe_1',
          'source-layer': 'Hmap-60sec_globe_1',
          'minzoom': 4,
          'maxzoom': 9,
          'filter': ['all', ['has', 'c'], ['!=', ['get', 'c'], 0]],
          'paint': {
            'fill-color': ['interpolate', ['linear'], ['get', 'c'], 0, 'rgb(201, 215, 244)', 10, 'rgb(0, 68, 205)'],
            'fill-opacity': 0.6,
            'fill-outline-color': 'rgba(0, 0, 0, 0)'
          }
        });
        layerIds.push('Hmap-60sec_globe_1_' + numberString);
      }
    }
    return { object, layerIds, layerIdPrefix };
  };

  // ハザードマップ（2km mesh）Hmap-60sec_globe_2
  const createHazardMapLoRes2 = () => {
    const layerIdPrefix = 'Hmap-60sec_globe_2_';
    const layerIds = [];
    const object = {
      sourceId: 'Hmap-60sec_globe_2',
      source: {
        type: 'vector',
        url: 'mapbox://interrisk.Hmap-60sec_globe_2'
      },
      layer: []
    };
    const tilesetNumbers = [8];

    for (const tilesetNumber of tilesetNumbers) {
      const numberString = tilesetNumber.toString();
      object.layer.push({
        'id': 'Hmap-60sec_globe_2_' + numberString,
        'layout': {
          'visibility': 'none'
        },
        'type': 'fill',
        'source': 'Hmap-60sec_globe_2',
        'source-layer': 'Hmap-60sec_globe_2',
        'minzoom': 4,
        'maxzoom': 9,
        'filter': ['all', ['has', 'c'], ['!=', ['get', 'c'], 0]],
        'paint': {
          'fill-color': ['interpolate', ['linear'], ['get', 'c'], 0, 'rgb(201, 215, 244)', 10, 'rgb(0, 68, 205)'],
          'fill-opacity': 0.6,
          'fill-outline-color': 'rgba(0, 0, 0, 0)'
        }
      });
      layerIds.push('Hmap-60sec_globe_2_' + numberString);
    }
    return { object, layerIds, layerIdPrefix };
  };

  // 洪水頻度変化予測
  const createFloodPrediction = () => {
    const layerIdPrefix = 'flood_freq_15min_globe_';
    const tilesetNumbers = [0, 1];
    const layerIds = [];

    const object = {
      sourceId: 'flood_freq_15min_globe',
      source: {
        type: 'vector',
        url: 'mapbox://interrisk.flood_freq_15min_globe'
      },
      layer: []
    };

    for (const tilesetNumber of tilesetNumbers) {
      const numberString = tilesetNumber.toString();
      object.layer.push({
        'id': 'flood_freq_15min_globe_' + numberString,
        'type': 'fill',
        'source': 'flood_freq_15min_globe',
        'source-layer': 'flood_freq_15min_globe',
        'minzoom': 4,
        'filter': ['all', ['has', numberString], ['!=', ['get', numberString], 0]],
        'layout': {
          'visibility': 'none'
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', numberString],
            'rgba(13, 20, 84, 0.6)',
            5,
            'rgba(48, 86, 166, 0.6)',
            25,
            'rgba(54, 145, 206, 0.6)',
            50,
            'rgba(91, 196, 220, 0.6)',
            75,
            'rgba(179, 223, 230, 0.6)',
            95,
            'rgba(193, 194, 194, 0.6)',
            105,
            'rgba(249, 243, 134, 0.6)',
            125,
            'rgba(250, 208, 0, 0.6)',
            250,
            'rgba(233, 98, 1, 0.6)',
            500,
            'rgba(236, 116, 150, 0.6)',
            1000,
            'rgba(230, 10, 17, 0.6)'
          ],
          'fill-outline-color': 'rgba(0, 0, 0, 0)'
        }
      });
      layerIds.push('flood_freq_15min_globe_' + numberString);
    }

    return { object, layerIds, layerIdPrefix };
  };

  // 高解像度ハザードマップ（90m mesh）
  const createHazardMapHiRes = () => {
    const layerIdPrefix = 'Hmap_3c_location_layer_';
    // 拠点周辺の90m mesh作成時に使用する元のレイヤー接頭辞
    const originalLayerIdPrefix = 'Hmap_3c_';
    const layerIds = [];
    const objects = [];

    // 一般版のレイヤー設定
    if (!isFinancialUser()) {
      for (let tilesetNumber = 1; tilesetNumber <= 14; tilesetNumber++) {
        const numberString = tilesetNumber.toString();
        objects.push({
          sourceId: 'Hmap_3c_' + numberString,
          source: {
            type: 'vector',
            url: 'mapbox://interrisk.Hmap_3c_' + numberString
          },
          layer: [
            {
              'id': 'Hmap_3c_' + numberString,
              'type': 'fill',
              'source': 'Hmap_3c_' + numberString,
              'source-layer': 'Hmap_3c_' + numberString,
              'minzoom': 13,
              'paint': {
                'fill-opacity': 0
              }
            }
          ]
        });
        for (let featureNumber = 0; featureNumber <= 15; featureNumber++) {
          layerIds.push('Hmap_3c_location_layer_' + featureNumber);
        }
      }
    } else {
      // 金融版のレイヤー設定
      for (let tilesetNumber = 1; tilesetNumber <= 14; tilesetNumber++) {
        const tilesetString = tilesetNumber.toString();
        const object = {
          sourceId: 'Hmap_3c_' + tilesetString,
          source: {
            type: 'vector',
            url: 'mapbox://interrisk.Hmap_3c_' + tilesetString
          },
          layer: []
        };

        for (let featureNumber = 0; featureNumber <= 15; featureNumber++) {
          const featureString = hazardMapLayerOrder[featureNumber].toString();
          object.layer.push({
            'id': 'Hmap_3c_location_layer_' + tilesetString + '_' + featureString,
            'layout': {
              'visibility': 'none'
            },
            'type': 'fill',
            'source': 'Hmap_3c_' + tilesetString,
            'source-layer': 'Hmap_3c_' + tilesetString,
            'minzoom': 13,
            'filter': ['all', ['has', featureString], ['!=', ['get', featureString], 0]],
            'paint': {
              'fill-color': [
                'interpolate',
                ['linear'],
                ['get', featureString],
                0,
                'rgb(201, 215, 244)',
                10,
                'rgb(0, 68, 205)'
              ],
              'fill-opacity': 0.6,
              'fill-outline-color': 'rgba(0, 0, 0, 0)'
            }
          });
          layerIds.push('Hmap_3c_location_layer_' + tilesetString + '_' + featureString);
        }
        objects.push(object);
      }
    }
    return { objects, layerIds, layerIdPrefix, originalLayerIdPrefix };
  };

  // 拠点周辺の高解像度ハザードマップのソース
  const createLocationHiresSource = (locationsGeojson) => {
    return {
      sourceId: 'location-hires-mesh',
      source: {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': locationsGeojson
        }
      }
    };
  };

  // 拠点周辺の高解像度ハザードマップのレイヤー
  const createLocationHiresLayer = (tilesetColumn) => {
    return {
      'id': 'Hmap_3c_location_layer_' + tilesetColumn,
      'type': 'fill',
      'source': 'location-hires-mesh',
      'minzoom': 13,
      'filter': ['all', ['has', tilesetColumn], ['!=', ['get', tilesetColumn], 0]],
      'paint': {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', tilesetColumn],
          0,
          'rgb(201, 215, 244)',
          10,
          'rgb(0, 68, 205)'
        ],
        'fill-opacity': 0.6,
        'fill-outline-color': 'rgba(0, 0, 0, 0)'
      }
    };
  };

  // 拠点レイヤー設定
  const createLocationLayer = (geojson) => {
    const layerId = 'location-point-layer';
    const sourceId = 'location-source';
    const object = {
      sourceId: 'location-source',
      source: {
        'type': 'geojson',
        'data': geojson
      },
      layer: {
        'id': 'location-point-layer',
        'type': 'symbol',
        'source': 'location-source',
        'layout': {
          'icon-image': [
            'match',
            ['get', 'accuracy_code'],
            // 初期値, roottop, parcel, point
            ['', '0', '1', '2'],
            'point-marker',
            // interpolated, intersection, approximate, street, それ以外
            'point-marker-low-accuracy'
          ],
          'icon-allow-overlap': true,
          'text-allow-overlap': true
        }
      }
    };

    return {
      object,
      layerId,
      sourceId
    };
  };
  return {
    createHazardMap,
    createHazardMapLoRes1,
    createHazardMapLoRes2,
    createFloodPrediction,
    createHazardMapHiRes,
    createLocationHiresSource,
    createLocationHiresLayer,
    createLocationLayer
  };
};

export default useTilesets;
