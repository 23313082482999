import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useState, useEffect, useRef } from 'react';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;

const useMapbox = (mapboxModules) => {
  // 再レンダリング禁止フラグ
  const isMapInitialized = useRef(false);
  const [map, setMap] = useState(null);
  const [language, setlanguage] = useState('ja');

  useEffect(() => {
    if (isMapInitialized.current) return;
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [137.0874, 38.61093],
      zoom: 5,
      hash: true,
      attributionControl: false,
      pitchWithRotate: false,
      dragRotate: false
    });

    // 検索ボックス
    if (mapboxModules.mapboxGeocoder) {
      map.addControl(new MapboxGeocoder(mapboxModules.mapboxGeocoder.options), mapboxModules.mapboxGeocoder.position);
    }

    // 現在地移動
    if (mapboxModules.geolocateControl) {
      map.addControl(new mapboxgl.GeolocateControl(mapboxModules.geolocateControl.options));
    }

    // 帰属表示
    if (mapboxModules.attributionControl) {
      map.addControl(new mapboxgl.AttributionControl(), mapboxModules.attributionControl.position);
    }

    // 縮尺
    if (mapboxModules.scaleControl) {
      map.addControl(
        new mapboxgl.ScaleControl(mapboxModules.scaleControl.options),
        mapboxModules.scaleControl.position
      );
    }

    // ズームイン／ズームアウトボタン
    if (mapboxModules.navigationControl) {
      map.addControl(
        new mapboxgl.NavigationControl(mapboxModules.navigationControl.options),
        mapboxModules.navigationControl.position
      );
    }

    // 言語切替
    if (mapboxModules.initLanguage) {
      mapboxModules.initLanguage(map);
    }

    setMap(map);
    isMapInitialized.current = true;
  }, [mapboxModules]);

  /*
   * 言語切り替えボタン
   */
  const LanguageButton = (props) => {
    let position = '';
    switch (props.page) {
      case 'hazard-map':
        position = 'panel-lang-hazard-map';
        break;
      case 'purchase-location':
        position = 'panel-lang-purchase-location';
        break;
      default:
        position = '';
    }

    return (
      <div className={'panel panel-bottom-right panel-lang ' + position} id="language-button">
        <button className="btn btn-sm btn-outline-primary m-2 px-4" id={'button-' + language} onClick={toggleLanguage}>
          {language === 'ja' ? 'English' : '日本語'}
        </button>
      </div>
    );
  };

  const toggleLanguage = () => {
    setlanguage(language === 'ja' ? 'en' : 'ja');
  };

  useEffect(() => {
    if (!map) return;
    if (!map.loaded()) return;

    map.getStyle().layers.forEach((thisLayer) => {
      if (thisLayer.id.indexOf('-label') > 0) {
        map.setLayoutProperty(thisLayer.id, 'text-field', ['get', 'name_' + language]);
      }
    });
  }, [language]);

  return {
    map,
    LanguageButton
  };
};

export default useMapbox;
