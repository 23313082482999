import { useContext, useEffect, useRef } from 'react';
import useMapbox from '../../../../hooks/useMapbox';
import useApi from '../../../../hooks/useApi';
import { toStringWithComma } from '../../../../utils/string';
import { AuthContext } from '../../../../context/AuthContext';

import useLocationEdit from './useLocationEdit';
import useMapboxModules from './useMapboxModules';
import usePermission from '../../../../hooks/usePermission';
import useServerError from '../../../../hooks/useServerError';

/**
 * データ入力画面：プレビューエリア
 */
function DataPreviewArea(props) {
  const modules = useMapboxModules();
  const userCredential = useContext(AuthContext);
  const { map, LanguageButton } = useMapbox(modules);
  const fileInput = useRef(null);

  // 拠点位置編集
  const { init, setLocations, start, submit, submittable } = useLocationEdit();

  const { updateLocation, downloadErrorGeocode } = useApi();

  const { isFinancialUser } = usePermission();

  const serverError = useServerError();

  // Mapboxインスタンスの初期化
  useEffect(() => {
    if (!map) {
      return;
    }

    init(map);
  }, [map]);

  // 拠点の追加
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!props.locations || props.locations.length === 0) {
      setLocations(null);
      return;
    }

    setLocations(props.locations);
  }, [props.locations]);

  // 拠点一覧行クリック
  const handleClickRow = (e) => {
    // 行に連動するマーカーをドラッグ可能にする。
    const location = props.locations.filter((v) => v.location_id === e.location_id)[0];
    start(location);
  };

  // 再アップロードボタン押下
  const handleClickReUpload = () => {
    fileInput.current.click();
  };

  // 再アップロードファイル選択後
  const handleChangeFile = async (e) => {
    const csv = e.target.files[0];

    // 同一ファイル名で再アップロード可能にする
    fileInput.current.value = '';

    props.reUploadLocation({ csv });
  };

  // 位置情報修正
  const handleClickEditLocation = async () => {
    const data = submit();
    let result;

    const purchasedSession = isFinancialUser() ? props.reUploadSessionId : props.sessionId;

    try {
      // 拠点仮データ更新
      result = await updateLocation({
        user_permission: `${userCredential.user_permission}`,
        purchased_session: purchasedSession,
        locations: [
          {
            location_id: data.location_id,
            location_lat: data.location_lat,
            location_lon: data.location_lon
          }
        ]
      });
    } catch {
      await serverError.open();
      return;
    }

    if (isFinancialUser()) {
      data.fixed = true;
      props.setErrorGeocode(result.count_error_geocoding);
    }

    // 親コンポーネントに変更通知
    props.changeLocation(data);
  };

  // ジオコーディングエラーデータダウンロードリンク押下
  const handleDownloadErrorGeocode = async () => {
    try {
      await downloadErrorGeocode({
        purchased_session: props.reUploadSessionId
      });
    } catch {
      await serverError.open();
      return;
    }
  };

  // 拠点一覧部分の作成
  const createLocationList = () => {
    const createLocationTable = () => {
      return (
        <>
          <div className="table-scroll flex-grow-1 h-0 mh-none min-h-380">
            <table className="table table-striped table-select text-center">
              <thead>
                <tr>
                  {isFinancialUser() && <th>Group ID</th>}
                  <th>
                    拠点名
                    <br />
                    Location name
                  </th>
                  {isFinancialUser() && (
                    <th>
                      住所
                      <br />
                      Address
                    </th>
                  )}
                  {isFinancialUser() && (
                    <th>
                      <span className="small">国コード</span>
                      <br />
                      Country code
                    </th>
                  )}
                  <th>
                    緯度
                    <br />
                    Lat
                  </th>
                  <th>
                    経度
                    <br />
                    Lon
                  </th>
                  <th>
                    建物
                    <br />
                    Building
                  </th>
                  <th>
                    設備
                    <br />
                    Assets
                  </th>
                  <th>
                    在庫資産
                    <br />
                    Contents
                  </th>
                  <th>
                    階数
                    <br />
                    Floor
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.locations.map((v) => (
                  <tr
                    onClick={!!v.location_lat && !!v.location_lon ? () => handleClickRow(v) : null}
                    key={v.location_id}
                    role="button"
                    className={v.fixed ? 'fixed' : null}>
                    {isFinancialUser() && <td>{v.group_id}</td>}
                    <td>
                      <a href="#">{v.location_name}</a>
                    </td>
                    {isFinancialUser() && <td>{v.address}</td>}
                    {isFinancialUser() && <td>{v.code_country}</td>}
                    <td>{v.location_lat}</td>
                    <td>{v.location_lon}</td>
                    <td>{v.building}</td>
                    <td>{v.assets}</td>
                    <td>{v.contents}</td>
                    <td>{v.floor}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {createLocationInfo(props.price, props.endDate, props.errorGeocode)}
        </>
      );
    };

    // 一般ユーザー拠点リスト作成
    if (!isFinancialUser()) {
      if (!props.locations || props.locations.length === 0) {
        return <div className="preview">Data Preview Area</div>;
      } else {
        return (
          <>
            <div className="h-100 d-flex flex-column gap-2">
              <div className="fw-bolder mb-1">
                拠点一覧<span className="ms-2">Location List</span>
              </div>
              {createLocationTable()}
            </div>
          </>
        );
      }
    }

    // 金融ユーザー拠点リスト作成
    if (isFinancialUser()) {
      if (props.locations === null) {
        return <div className="preview">Data Preview Area</div>;
      } else {
        return (
          <>
            <div className="h-100 d-flex flex-column gap-2">
              <div className="fw-bolder mb-1">
                ジオコーディング失敗or精度が低い拠点リスト
                <br />
                <span>Location list with low geocoding accuracy or geocoding failure</span>
              </div>
              {createLocationTable()}
            </div>
          </>
        );
      }
    }
  };

  // 拠点一覧下部の作成
  const createLocationInfo = (price, endDate, errorGeocode) => {
    if (isFinancialUser()) {
      // 金融版ユーザーはジオコーディングエラーを表示
      if (errorGeocode === null) return null;

      const isErrorGeocode = !!(errorGeocode > 0);

      return (
        <div className="border fw-bolder p-3">
          {isErrorGeocode ? (
            <>
              <div className="text-danger small">
                ジオコーディング失敗or精度が低い拠点があります（合計{errorGeocode}件）。
                <br />
                Some locations failed to be geocoded or need to be higher accuracy (Total number is {
                  errorGeocode
                }). <br />
                該当拠点をダウンロードして、住所を整形もしくは緯度経度を直接記載して再アップロードください。
                <br />
                Please download the list, modify address or specify Lat&Lon, and re-upload it.
                <br />
              </div>
              <div>
                <a href="#" onClick={handleDownloadErrorGeocode}>
                  ダウンロードはこちら。<span>Download here.</span>
                </a>
              </div>
              <div className="sticky-buttons mb-0 pb-0 pt-2" style={{ zIndex: '20' }} >
                <button
                  type="button"
                  className={'btn btn-lg btn-primary rounded-pill pe-4'}
                  onClick={handleClickReUpload}>
                  <span className="icon icon-upload"></span>Re-upload after revision
                </button>
                <input
                  type="file"
                  className="form-control d-none"
                  id="file_upload"
                  accept="text/csv"
                  ref={fileInput}
                  onChange={handleChangeFile}
                />
              </div>
            </>
          ) : (
            <div className="text-danger">
              アップロード完了。画面右下の分析開始ボタンを押下ください
              <br />
              Upload is completed. Please click “Run Analysis” button at the bottom right
            </div>
          )}
        </div>
      );
    } else {
      // 有償版ユーザーは金額と有効期限を表示
      return (
        <div className="bg-lightyellow fw-bolder p-2">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              支払金額<span className="ms-2">Payment amount</span>
            </div>
            <div className="fs-large text-orange">JPY {toStringWithComma(price)}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              プレミアム版有効期限<span className="ms-2">Premium version expiration date (JST)</span>
            </div>
            <div>{endDate}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-7">{createLocationList()}</div>
            <div className="col-5 d-flex align-items-center">
              <div className="map-container mh-500px">
                <div className="mapboxgl-map" id="map" />
                <div className="panel panel-top-right">
                  <button
                    type="button"
                    className={`btn btn-sm btn-outline-primary rounded m-2 lh-1 ${submittable ? '' : 'disabled'}`}
                    tabIndex="-1"
                    onClick={handleClickEditLocation}>
                    位置情報修正
                    <br />
                    Click to revise the location
                  </button>
                </div>
                <LanguageButton page="purchase-location" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <serverError.ServerError />
    </>
  );
}

export default DataPreviewArea;
