import { useContext, useRef, useState, useEffect } from 'react';
import { AuthContext } from '../../../context/AuthContext';

import useApi from '../../../hooks/useApi';
import usePermission from '../../../hooks/usePermission';
import useTilesets from './useTilesets';
import useModal from '../../../hooks/useModal';
import { sleep } from '../../../utils/thread';
import { loadImageAsPromise } from '../../../utils/thread';

import { polygon } from '@turf/helpers';
import circle from '@turf/circle';
import transformRotate from '@turf/transform-rotate';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import booleanIntersects from '@turf/boolean-intersects';

const useLocationData = () => {
  const userCredential = useContext(AuthContext);

  const { isFreeUser, isFinancialUser } = usePermission();
  const { getLocationMapData } = useApi();
  const { createLocationLayer, createHazardMapHiRes } = useTilesets();

  const modal = useModal();

  const map = useRef(null);
  const [geojson, setGeojson] = useState(null);
  const [serverError, setServerError] = useState(false);

  const [eventObject, setEventObject] = useState(null);
  const [locationFeatures, setLocationFeatures] = useState([]);
  const [firstHiResLayer, setFirstHiResLayer] = useState(() => {});
  const clickedLocations = useRef([]);
  const flyToAnimationFlg = useRef(false);

  // 初期化
  const init = (_map) => {
    // 無償版は処理対象外
    if (isFreeUser()) return;

    map.current = _map;
  };

  // Analysisフィルターチェック時の制御
  const [dispLocationList, setDispLocationList] = useState([]);
  const initDispFlg = useRef(false);
  const locationVisible = useRef(true);

  // 一般版ハザードマップの拠点レイヤー表示処理
  useEffect(() => {
    if (isFinancialUser()) return;

    if (!map.current) return;

    if (!initDispFlg.current) {
      // 初回ロード時
      map.current.once('load', async () => {
        let res;
        try {
          res = await getLocationMapData({
            company_id: userCredential.company_id
          });
        } catch (error) {
          setServerError(true);
          return;
        }
        setGeojson(res.geojson);

        const { object, layerId } = createLocationLayer(res.geojson);

        let image;
        try {
          image = await loadImageAsPromise(map.current, `${process.env.PUBLIC_URL}/img/icon_map_marker.png`);
        } catch (error) {
          setServerError(true);
          return;
        }
        map.current.addImage('point-marker', image);
        map.current.addSource(object.sourceId, object.source);

        // 初期表示ではすべての拠点場所を表示する
        map.current.addLayer(object.layer);
        showLocations(layerId);

        map.current.on('click', layerId, onClick);
      });
      initDispFlg.current = true;
    } else {
      // 拠点フィルターのチェックボックスが変更時
      if (!map.current.loaded()) return;
      const filterExpressions = ['all', ['any']];
      for (let i = 0; i < dispLocationList.length; i++) {
        filterExpressions[1].push(['==', 'analysis_id', dispLocationList[i]]);
      }

      const { layerId } = createLocationLayer();
      if (map.current.getLayer(layerId)) {
        map.current.setFilter(layerId, filterExpressions);
      }
      showLocations(layerId);
    }
  }, [dispLocationList]);

  // 金融版ハザードマップの拠点レイヤー表示処理
  const initFinancialDispFlg = useRef(false);

  useEffect(() => {
    if (!isFinancialUser()) return;

    if (!map.current) return;

    const { layerId, sourceId } = createLocationLayer();
    if (!initFinancialDispFlg.current && !(dispLocationList.length === 0)) {
      // 初回ロード時
      const initAddTileset = async () => {
        modal.loading(true);
        let res;
        try {
          res = await getLocationMapData({
            company_id: userCredential.company_id,
            analysis_id_list: dispLocationList
          });
        } catch (error) {
          modal.loading(false);
          setServerError(true);
          return;
        }
        setGeojson(res.geojson);

        const { object } = createLocationLayer(res.geojson);

        let images;
        try {
          images = await Promise.all([
            loadImageAsPromise(map.current, `${process.env.PUBLIC_URL}/img/icon_map_marker.png`),
            loadImageAsPromise(map.current, `${process.env.PUBLIC_URL}/img/icon_map_marker_active.png`),
          ]);
        } catch (error) {
          modal.loading(false);
          setServerError(true);
          return;
        }
        map.current.addImage('point-marker', images[0],);
        map.current.addImage('point-marker-low-accuracy', images[1]);
        map.current.addSource(object.sourceId, object.source);
        map.current.addLayer(object.layer);
        showLocations(layerId);

        map.current.on('click', layerId, onClick);

        modal.loading(false);
      };
      if (map.current.loaded()) {
        initAddTileset();
      } else {
        map.current.once('idle', initAddTileset);
      }
      initFinancialDispFlg.current = true;
    } else {
      // Analysis ID チェックOn/Off時
      modal.loading(true);
      (async () => { 
        await sleep(100);

        let tempGeojson;
        if (dispLocationList.length === 0) {
          // チェックなし
          tempGeojson = {
            'features': [],
            'type': 'FeatureCollection'
          };
        } else {
          // チェックあり
          let res;
          try {
            res = await getLocationMapData({
              company_id: userCredential.company_id,
              analysis_id_list: dispLocationList
            });
          } catch (error) {
            modal.loading(false);
            setServerError(true);
            return;
          }
          tempGeojson = res.geojson;
        }
        setGeojson(tempGeojson);
        if (map.current.getSource(sourceId)) {
          map.current.getSource(sourceId).setData(tempGeojson);
        }
        showLocations(layerId);
        map.current.on('sourcedata', onLoadSource);
      })();
    }
  }, [dispLocationList]);

  useEffect(() => {});

  // 拠点データ表示
  const setVisible = (visible) => {
    locationVisible.current = visible;

    if (!map.current) return;
    if (dispLocationList.length === 0) return;

    const { layerId } = createLocationLayer();
    if (!map.current.getLayer(layerId)) return;

    if (!visible) {
      // 非表示の場合はローダーなし
      showLocations(layerId);
      return;
    }

    modal.loading(true);
    showLocations(layerId);
    map.current.on('sourcedata', onLoadSource);
  };

  // 拠点表示切替
  const showLocations = (layerId) => {
    if (locationVisible.current) {
      map.current.setLayoutProperty(layerId, 'visibility', 'visible');
    } else {
      map.current.setLayoutProperty(layerId, 'visibility', 'none');
    }  
  }

  // ソース更新時処理
  const onLoadSource = (e) => {
    const { sourceId } = createLocationLayer();
    if (e.sourceId === sourceId && e.isSourceLoaded 
        && e.sourceDataType !== 'metadata' && e.sourceDataType !== 'visibility') {
      map.current.off('sourcedata', onLoadSource);
      modal.loading(false);
    }
  };

  // 拠点クリック処理
  const onClick = (e) => {
    setEventObject(e);
  };

  useEffect(() => {
    if (!eventObject) return;
    // flyToによるアニメーション中の場合
    if (flyToAnimationFlg.current) return;
    if (!geojson.features) return;

    const userLocations = geojson.features;
    const { layerId } = createLocationLayer();

    const features = map.current.queryRenderedFeatures(eventObject.point);
    const clickLocations = features.filter((item) => item.layer.id === layerId);

    // クリックした地点に拠点が存在しない場合
    if (!clickLocations.length) return;

    // クリックした地点にある一番上の拠点レイヤー情報を取得
    const clickLocation = clickLocations[0];

    // クリックした地点の座標をユーザーの拠点Geojsonから取得
    const locationGeojson = userLocations.filter(
      (item) =>
        item.properties.analysis_id === clickLocation.properties.analysis_id &&
        item.properties.location_id === clickLocation.properties.location_id
    );
    const coordinates = locationGeojson[0].geometry.coordinates;

    if (isFinancialUser()) {
      flyTo(coordinates);
      firstHiResLayer();
      return;
    }

    // 初めてクリックされた拠点である場合
    const locationAnalysisId = clickLocation.properties.analysis_id + '_' + clickLocation.properties.location_id;
    if (clickedLocations.current.indexOf(locationAnalysisId) === -1) {
      const currentMapZoomLevel = map.current.getZoom();
      const currentMapCenter = [map.current.getCenter().lng, map.current.getCenter().lat];

      const compCoordinates = (currentValue, index) => currentValue === currentMapCenter[index];

      if (currentMapZoomLevel === 14 && coordinates.every(compCoordinates)) {
        firstHiResLayer();
        clickedLocations.current.push(locationAnalysisId);
        const locationMeshGeojson = getBBox(coordinates[0], coordinates[1]);
        getFeature(locationMeshGeojson);
      } else {
        map.current.once('zoomend', () => {
          firstHiResLayer();
        });
        flyToAnimationFlg.current = true;
        // クリックされた拠点情報の追加
        clickedLocations.current.push(locationAnalysisId);
        // クリック後のアニメーション中mapへの操作不可
        interactionsToggle();
        const locationMeshGeojson = getBBox(coordinates[0], coordinates[1]);
        zoomEnd(locationMeshGeojson);
      }
    }

    flyTo(coordinates);
  }, [eventObject]);

  // feature取得処理
  const getFeature = (locationMeshGeojson) => {
    // 操作不可を解除
    flyToAnimationFlg.current = false;
    interactionsToggle();

    // 高解像度ハザードマップレイヤーのIDを取得
    const layers = map.current.getStyle().layers;
    const { originalLayerIdPrefix } = createHazardMapHiRes();
    const hiresFeatures = layers.filter((item) => item.id.startsWith(originalLayerIdPrefix)).map((item) => item.id);

    // 高解像度ハザードマップfeatureと拠点周辺エリアが交差する地点を取得
    const locatinMesh = [];
    for (const hiresFeature of hiresFeatures) {
      const features = map.current.queryRenderedFeatures({
        layers: [hiresFeature]
      });
      if (features.length) {
        locatinMesh.push(...features.filter((tileset) => booleanIntersects(locationMeshGeojson, tileset)));
      }
    }
    setLocationFeatures(locatinMesh);
  };

  // アニメーション終了後の処理
  const zoomEnd = (locationMeshGeojson) => {
    map.current.once('zoomend', () => {
      const sourceLoaded = map.current.areTilesLoaded();
      if (sourceLoaded) {
        // ビューポート上のレイヤーレンダリングが完了している場合
        getFeature(locationMeshGeojson);
      } else {
        // ビューポート上のレイヤーレンダリングが完了していない場合
        map.current.once('idle', () => {
          getFeature(locationMeshGeojson);
        });
      }
    });
  };

  // 拠点周囲1km正方形範囲取得処理
  const getBBox = (lng, lat) => {
    // 円の頂点を４つに設定した半径√2のひし形を作成
    // 45°傾け、正方形を作成

    // ひし形を作成
    const center = [lng, lat];
    // 半径を√2に設定
    const radius = Math.sqrt(2);

    const options = { steps: 4, units: 'kilometers' };
    const diamond = circle(center, radius, options);

    // ひし形の頂点４つを取得
    const coordinatesOnCircle = diamond.geometry.coordinates;

    // ひし形の頂点を取得し、四角形に変換
    const poly = polygon(coordinatesOnCircle);

    // ひし形を45°傾けた四角形の頂点をgeojson形式で取得
    const rotatedPoly = transformRotate(poly, 45, { pivot: center });

    const isBBox = bbox(rotatedPoly);
    const isBBoxPolygon = bboxPolygon(isBBox);

    return isBBoxPolygon;
  };

  // ズームアニメーション中はマップの操作不可
  const interactionsToggle = () => {
    if (!flyToAnimationFlg.current) {
      map.current['scrollZoom'].enable();
      map.current['boxZoom'].enable();
      map.current['dragPan'].enable();
      map.current['keyboard'].enable();
      map.current['doubleClickZoom'].enable();
      map.current['touchZoomRotate'].enable();
    } else {
      map.current['scrollZoom'].disable();
      map.current['boxZoom'].disable();
      map.current['dragPan'].disable();
      map.current['keyboard'].disable();
      map.current['doubleClickZoom'].disable();
      map.current['touchZoomRotate'].disable();
    }
  };

  const flyTo = (coordinates) => {
    map.current.flyTo({
      center: coordinates,
      zoom: 14,
      speed: 2
    });
  };

  return {
    init,
    setVisible,
    setDispLocationList,
    setFirstHiResLayer,
    geojson,
    serverError,
    locationFeatures,
    Modal: modal.Modal
  };
};

export default useLocationData;
