const HeaderLogo = () => {
  return (
    <>
      <div className="header-left">
        <div className="brand">
          <div className="logo">
            <img width="141" height="36" src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Logo" />
          </div>
          <div className="name">
            <span>洪水リスクファインダー</span>
            <span>Flood Risk Finder</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderLogo;
