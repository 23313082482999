import Description from './components/Description/Description';
import ScenarioSelector from './components/ScenarioSelector/ScenarioSelector';
import ResultTable from './components/ResultTable/ResultTable';
import Download from './components/Download/Download';

import { Header, Footer } from '../../layouts/index';
import useApi from '../../hooks/useApi';
import useServerError from '../../hooks/useServerError';
import useAlert from '../../hooks/useAlert';
import useModal from '../../hooks/useModal';
import MultiLine from '../../components/MultiLine';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { PageLoader } from '../../views/index';

function AnalysisResult() {
  // 認証情報
  const userCredential = useContext(AuthContext);

  // 権限
  const navigate = useNavigate();

  // API
  const { searchAnalysisResult, getUserInfo } = useApi();
  
  const serverError = useServerError();
  const errorAlert = useAlert();
  const [errMessage, setErrMessage] = useState('');
  const pageLoaderModal = useModal();

  // シナリオ選択
  const [scenario, setScenario] = useState('ssp126');
  const [result, setResult] = useState({});
  const [responseFinishFlg, setResponseFinishFlg] = useState(false);
  const [hasLocation, setHasLocation] = useState(false);

  const analysisIds = useRef([]);
  const locationNames = useRef([]);
  const groupIds = useRef([]);

  // 初期表示時
  useEffect(() => {
    (async () => {
      let res1;
      try {
        res1 = await getUserInfo({
          company_id: userCredential.company_id,
          user_permission: userCredential.user_permission
        });
      } catch {
        await serverError.open();
        setResponseFinishFlg(true);
        return;
      }
      setHasLocation(res1.has_location);
      setResponseFinishFlg(true);
    })();
  }, []);

  // シナリオ変更
  const handleChangeSelector = async (selectedScenario) => {
    setScenario(selectedScenario);
  };

  // フィルター変更
  const handleChangeFilter = async (selectedAnalysisIds, selectedLocationNames, selectedGroupIds) => {
    analysisIds.current = selectedAnalysisIds;
    locationNames.current = selectedLocationNames;
    groupIds.current = selectedGroupIds;

    const params = {
      user_permission: `${userCredential.user_permission}`,
      company_id: userCredential.company_id,
      analysis_id_list: [...selectedAnalysisIds],
      location_name_list: [...selectedLocationNames],
      group_id_list: [...selectedGroupIds]
    };

    handleOpenPageLoader(true);
    let result;
    try {
      result = await searchAnalysisResult(params);
    } catch {
      handleOpenPageLoader(false);
      await serverError.open();
      return;
    }
    handleOpenPageLoader(false);
    setResult(result);
  };

  // エラー表示
  const handleOpenError = async (message) => {
    setErrMessage(message);
    await errorAlert.open();  
  };

  // PageLoader表示
  const handleOpenPageLoader = async (isOpen) => {
    pageLoaderModal.loading(isOpen);
  };

  // 権限の無いユーザーはアクセス不可
  if (responseFinishFlg && !hasLocation) {
    navigate('/');
    return;
  }

  if (responseFinishFlg) {
    return (
      <div>
        <Header />
        <main className="main">
          <div className="container">
            <Description />
            <ScenarioSelector
              onChangeScenario={handleChangeSelector}
              onChangeFilter={handleChangeFilter}
              openServerError={serverError.open}
              openPageLoader={handleOpenPageLoader}
            />
            <ResultTable scenario={scenario} result={result} />
            <Download
              scenario={scenario}
              analysisIds={analysisIds}
              locationNames={locationNames}
              groupIds={groupIds}
              openServerError={serverError.open}
              openError={handleOpenError}
              openPageLoader={handleOpenPageLoader}
            />
          </div>
          <serverError.ServerError />
          <errorAlert.Alert>
            <div>
              <MultiLine text={errMessage} />
            </div>
          </errorAlert.Alert>
          <pageLoaderModal.Modal>
            <PageLoader />
          </pageLoaderModal.Modal>
        </main>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
}

export default AnalysisResult;
