import { useEffect, useState } from 'react';

import PanelItems from './PanelItems.js';

const PanelHazardMap = (props) => {
  // 現在気候(2020)の再現期間100年, 500m mesh初期表示
  const [checkedList, setCheckedList] = useState(PanelItems.HazardMap.yearScenario);

  // レイヤーの初期表示
  useEffect(() => {
    const checkedValues = Object.entries(checkedList)
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);
    props.setLayersList('HazardMap', checkedValues);
  }, []);

  // チェックボックスの状態更新
  const onCheckBox = async (e) => {
    const currentCheckedList = JSON.parse(JSON.stringify(checkedList));
    currentCheckedList[e.target.id].checked = !currentCheckedList[e.target.id].checked;

    const checkedValues = Object.entries(currentCheckedList)
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.value);

    if (props.setLayersList('HazardMap', checkedValues)) {
      setCheckedList(currentCheckedList);
    } else {
      await props.open();
    }
  };

  // チェックボックスの作成
  const makeCheckBox = () => {
    return (
      <>
        <div className="small fw-bolder mb-1">Year, Scenario</div>
        {Object.keys(checkedList).map((key) => {
          return (
            <div className="form-check small" key={key}>
              <input
                className="form-check-input"
                type="checkbox"
                id={key}
                onChange={onCheckBox}
                checked={checkedList[key].checked}
              />
              <label className="form-check-label d-block" htmlFor={key}>
                {checkedList[key].value}
              </label>
            </div>
          );
        })}
      </>
    );
  };

  // データレイヤパネルの作成処理
  return (
    <div className="card border-0">
      <div className="badge-plan">Free</div>

      <div className="card-header bg-transparent border-bottom-0 d-flex flex-column gap-1 mb-0">
        <div className="d-flex gap-1 align-items-center flex-wrap lh-1 fw-bolder">
          ハザードマップ<span className="fs-xsmall fw-normal">【再現期間100年】</span>
        </div>
        <div className="d-flex gap-1 align-items-baseline flex-wrap lh-1 fw-bolder">
          Hazard map<span className="fs-xsmall fw-normal"> (RP100 years)</span>
        </div>
      </div>

      <div className="card-body pt-0">{makeCheckBox()}</div>
    </div>
  );
};

export default PanelHazardMap;
