import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { HazardMap, PurchaseLocation, AnalysisResult, Page403 } from './views/index';
import { AuthProvider } from './context/AuthContext';
import { TokenProvider } from './context/TokenContext';
import { Auth0ProviderWithNavigate } from './auth/auth0-provider-with-navigate';
import { AuthenticationGuard } from './auth/authentication-guard';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

function App() {
  return (
    <TokenProvider>
      <BrowserRouter basename="/web/">
        <Auth0ProviderWithNavigate>
          <AuthProvider>
            <Routes>
              <Route path={`/`} element={<AuthenticationGuard component={HazardMap} />} />
              <Route path={`/analysisResult`} element={<AuthenticationGuard component={AnalysisResult} />} />
              <Route path={`/purchaseLocation`} element={<AuthenticationGuard component={PurchaseLocation} />} />
              <Route path={`/403`} element={<Page403 />} />
              <Route path={`*`} element={<Navigate to="/" />} />
            </Routes>
          </AuthProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </TokenProvider>
  );
}

export default App;
