import { useEffect, useState } from 'react';
import usePermission from '../../../../hooks/usePermission';

// 拠点データパネルの作成処理
const PanelLocationToggle = (props) => {
  const { isFreeUser } = usePermission();

  const textAshGray = isFreeUser() ? 'text-ashgray' : '';

  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onChange = (checked) => {
    props.setVisible(checked);
    setChecked(checked);
  };

  useEffect(() => {
    if (!props.userInfo) return;
    if (!props.userInfo.analysis_history) return;

    setChecked(true);
    setDisabled(false);
  }, [props.userInfo]);

  return (
    <>
      <div className={'card border-0' + (isFreeUser() ? ' card-premium' : '')}>
        <div className="card-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-column gap-1 mb-0">
              <div className={'d-flex gap-1 align-items-center flex-wrap lh-1 fw-bolder ' + textAshGray}>
                拠点データ
              </div>
              <div className={'d-flex gap-1 align-items-baseline flex-wrap lh-1 fw-bolder ' + textAshGray}>
                Location data
              </div>
            </div>
            <div className="form-check form-switch mb-0 fs-large">
              <input
                className="form-check-input"
                type="checkbox"
                id="switch1"
                onChange={() => onChange(!checked)}
                checked={checked}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PanelLocationToggle;
