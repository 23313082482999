/**
 * 数値を3桁区切り固定小数点の文字列に変換する
 * @param {number} value 変換元
 * @param {number} decimalPlaces 小数点以下桁数
 * @returns 
 */
export const toStringWithComma = (value, decimalPlaces = 0) => {
  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping: true,
  };
  return value.toLocaleString('ja-JP', options);
};