import { useState } from 'react';
import { Progress } from 'antd';
import MultiLine from '../components/MultiLine';
import PageLoader from '../views/PageLoader/page-loader';

const useProgressBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({});

  const loading = (loadingFlg) => {
    setIsLoading(loadingFlg);
  };

  const ProgressBar = () => {
    return isLoading ? (
      <div className="progress-modal-overlay progress-bar">
        {!(Object.keys(status).length === 0) ? (
        <div className="modal">
          <div className="modal-content">
              <MultiLine text={status.message} />
              <Progress percent={status.progress} size={[300, 20]} />
          </div>
        </div>
        ) : 
        (
        <div className="page-layout">
          <PageLoader />
        </div>
        )}
      </div>
    ) : (
      <></>
    );
  };

  return {
    ProgressBar,
    setStatus,
    loading
  };
};

export default useProgressBar;
