import { useState } from 'react';

const useSearch = (comboboxRef) => {
  // 検索ボックスへ入力された文字
  const [searchQuery, setSearchQuery] = useState('');

  // 拠点項目の検索処理
  const serchCheckbox = (e) => {
    setSearchQuery(e.target.value);
    const checkListItems = comboboxRef.current.querySelectorAll('.check-list-item');
    checkListItems.forEach((checkListItem) => {
      const childLabel = checkListItem.querySelector('label');
      if (e.target.value && childLabel.innerText.indexOf(e.target.value) === -1) {
        checkListItem.style.display = 'none';
      } else {
        checkListItem.style.display = '';
      }
    });
  };

  return [searchQuery, serchCheckbox];
};

export default useSearch;
