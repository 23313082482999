import { useEffect, useRef, useState } from "react";

function ComboBox(props) {
  const insideRef = useRef(null);

  // コンボボックス有効フラグ
  const [isActive, setIsActive] = useState(false);
  // コンボボックスタイトル
  const [title, setTitle] = useState(props.title);
  // 全選択フラグ
  const [isAll, setIsAll] = useState(false);
  // チェック済値
  const [checkedValues, setCheckedValues] = useState([]);
  // フィルター用
  const [displays, setDisplays] = useState([]);

  // マウント時処理
  useEffect(() => {
    setDisplays(props.items.map(v => v.value));
    setCheckedValues(props.items.map(v => v.value));
  }, [props.items]);

  // タイトルクリック
  const handleClickTitle = () => {
    setIsActive(!isActive);
  };

  // 検索ボックス入力
  const handleInputSearch = (e) => {    
    setDisplays(props.items.filter(v => v.label.indexOf(e.target.value) !== -1).map(v => v.value));
  };

  // TODO 検索ボックスキーダウン
  const handleKeydownSearch = () => {
/*
    if (e.keyCode === 13) {
        if (title) {
            title.dispatchEvent(new MouseEvent("click"));
        }
        e.preventDefault();
        return false;
    }
*/
  };

  // 全選択
  const handleChangeAll = () => {
    if(isAll) {
      // TODO あり ⇒ (state) 捨てる
      setCheckedValues([]);
      setIsAll(false);
    } else {
      // TODO チェックなし ⇒ (state)チェック済みに全部突っ込む
      setCheckedValues([...props.items.map(v => v.value)]);
      setIsAll(true);
    }
  };

  // チェックボックス選択
  const handleChangeCheckbox = (e) => {
    if (checkedValues.includes(e.target.value)) {
      setCheckedValues(
        checkedValues.filter((checkedValue) => checkedValue !== e.target.value)
      );
    } else {
      setCheckedValues([...checkedValues, e.target.value]);
    }
  }

  // チェック状態変更
  useEffect(() => {
    // タイトル変更
    changeTitle();

    // 全選択チェックボックスの選択状態変更
    if(props.items.length === checkedValues.length) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }

    // 親コンポーネントにチェックボックスの選択状態通知
    props.onChange(checkedValues);
  }, [checkedValues]);

  // タイトル変更
  const changeTitle = () => {
      if(checkedValues.length === 0) {
        setTitle(props.title);
      } else if(checkedValues.length === 1) {
        setTitle(props.items.filter(v => v.value === checkedValues[0])[0].label);
      } else {
        // TODO 1件目が順不同
        const text = `${props.items.filter(v => v.value === checkedValues[0])[0].label}（他${checkedValues.length - 1}件）`
        setTitle(text);
      }
  };


  useEffect(() => {
    //対象の要素を取得
    const el = insideRef.current;

    //対象の要素がなければ何もしない
    if (!el) return;

    //クリックした時に実行する関数
    const hundleClickOutside = (e) => {
      if (!el?.contains(e.target)) {
        //ここに外側をクリックしたときの処理
        setIsActive(false);
      } else {
        //ここに内側をクリックしたときの処理 ⇒ 何もしない
      }
    };

    //クリックイベントを設定
    document.addEventListener("click", hundleClickOutside);

    //クリーンアップ関数
    return () => {
      //コンポーネントがアンマウント、再レンダリングされたときにクリックイベントを削除
      document.removeEventListener("click", hundleClickOutside);
    };
  }, [insideRef]);

  return (
    <div className={`combobox combobox-sm w-auto d-inline-block me-3 ${isActive ? 'active': ''}`} ref={insideRef}>
      <div className="combobox-title" onClick={handleClickTitle}>{title}</div>
      <div className="combobox-dropdpwn">
        <div className="combobox-search">
          <input type="text" className="form-control" onInput={handleInputSearch}/>
        </div>
        <ul className="combobox-list">
          <li>
            <div className="form-check">
              <input
                className="form-check-input combobox-all"
                type="checkbox"
                name=""
                checked={isAll}
                id={`${props.id}-0`}
                onChange={handleChangeAll}
              />
              <label className="form-check-label d-block" htmlFor={`${props.id}-0`}>
                (Select All)
              </label>
            </div>
          </li>
          {
            props.items.map((v, i) => {
              return(
                <li style={{display: displays.includes(v.value) ? '': 'none'}}>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="" value={v.value} checked={checkedValues.includes(v.value)} id={`${props.id}-${i + 1}`} onChange={handleChangeCheckbox}/>
                    <label className="form-check-label d-block" htmlFor={`${props.id}-${i + 1}`}>
                      {v.label}
                    </label>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default ComboBox;
