import { useState, useEffect } from 'react';
import usePermission from '../../../../hooks/usePermission';

const useCheckbox = (setDispLocationList, open) => {
  const { isFinancialUser } = usePermission();

  // タイトル
  const [comboboxTitle, setComboboxTitle] = useState('Analysis ID');

  // チェックボックスの状態管理
  const [checkboxList, setCheckboxList] = useState([]);

  // チェックボックスがすべてチェック状態かどうかのフラグ
  const [checkSelectAllFlg, setCheckSelectAllFlg] = useState(false);

  const init = (analysisHistory) => {
    const analysisIds = analysisHistory.map((item) => item.analysis_id);
    
    if (isFinancialUser()) {
      const initCheckboxList = [];
      for (let i = 0; i < analysisIds.length; i++) {
        initCheckboxList.push({ id: analysisIds[i], checked: false });
      }
      // 初期表示時、ソートしたリストの一番最初のみOn
      initCheckboxList[0].checked = true;
      
      setAnalysisHistorys(analysisHistory);
      setCheckboxList(initCheckboxList);
    } else {
      const initCheckboxList = [];
      for (let i = 0; i < analysisIds.length; i++) {
        initCheckboxList.push({ id: analysisIds[i], checked: true });
      }

      setCheckboxList(initCheckboxList);
    }
  };

  // チェックボックス押下時
  const checkboxClick = (e) => {
    setCheckboxList((prevCheckboxItems) => {
      return prevCheckboxItems.map((checkbox) => {
        if (checkbox.id === e.target.id) {
          // チェックボックスの状態を反転
          return { ...checkbox, checked: !checkbox.checked };
        }
        return checkbox;
      });
    });
  };

  // (Select All)押下時
  const toggleCheckAll = () => {
    // (Select All)にチェックがついている場合、チェックリストのチェックをすべて外す
    if (checkSelectAllFlg) {
      setCheckboxList((prevCheckboxes) => {
        return prevCheckboxes.map((checkbox) => {
          return { ...checkbox, checked: false };
        });
      });
      // (Select All)にチェックが付いていない場合、チェックリストのチェックをすべて付ける
    } else {
      setCheckboxList((prevCheckboxes) => {
        return prevCheckboxes.map((checkbox) => {
          return { ...checkbox, checked: true };
        });
      });
    }
  };

  // チェックボックスの状態が変化したとき、タイトルを変更する
  useEffect(() => {
    const checkedId = checkboxList.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.id);
    if (!checkedId.length) {
      setComboboxTitle('Analysis ID');
    } else if (checkedId.length === 1) {
      setComboboxTitle(checkedId[0]);
    } else {
      setComboboxTitle(checkedId[0] + `他（${checkedId.length - 1}）件`);
    }
  }, [checkboxList]);

  // チェックボックスがすべてチェック状態かどうかの判定
  useEffect(() => {
    setCheckSelectAllFlg(Object.values(checkboxList).every((value) => value.checked === true));
  }, [checkboxList]);

  // チェックボックスの状態が変化したとき、tilesetのフィルター条件を作成
  const [analysisHistorys, setAnalysisHistorys] = useState([]);
  const [prevCheckboxList, setPrevCheckboxList] = useState([]);
  useEffect(() => {
    if (isFinancialUser()) {
      // 現在チェックされている項目、前回チェックされていた項目のIDを取得
      const checkIds = checkboxList.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.id);
      const prevCheckIds = prevCheckboxList.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.id);

      if (checkIds.length === prevCheckIds.length) return;

      (async () => {
        // 項目がチェックが外された場合
        if (!checkIds.length > prevCheckIds.length) {
          setPrevCheckboxList(checkboxList);
          setDispLocationList(checkIds);
          return;
        }

        // 拠点データの合計を算出
        let totalCount = 0;
        const addCheckIds = checkIds.filter((id) => !prevCheckIds.includes(id));
        for (const addCheckId of addCheckIds) {
          const object = analysisHistorys.find((location) => location.analysis_id === addCheckId);
          totalCount += object.count;
        }

        // 10000件以下の場合
        if (totalCount <= 10000) {
          setPrevCheckboxList(checkboxList);
          setDispLocationList(checkIds);
          return;
        }

        // 10000件より多い場合
        if (await open()) {
          // Popupでtrue
          setPrevCheckboxList(checkboxList);
          setDispLocationList(checkIds);
          return;
        }
        // Popupでfalse
        const tempCheckboxList = Array.from(checkboxList);
        for (let i = 0; i < tempCheckboxList.length; i++) {
          for (const addCheckId of addCheckIds) {
            if (tempCheckboxList[i].id === addCheckId) tempCheckboxList[i].checked = false;
          }
        }
        setPrevCheckboxList(tempCheckboxList);
        setCheckboxList(tempCheckboxList);
      })();
    } else {
      const checkedId = checkboxList.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.id);
      setDispLocationList(checkedId);
    }
  }, [checkboxList]);

  return [init, comboboxTitle, { checkboxClick, toggleCheckAll }, checkboxList, checkSelectAllFlg];
};

export default useCheckbox;
