import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import useApi from '../../hooks/useApi';
import usePermission from '../../hooks/usePermission';
import useProgressBar from '../../hooks/useProgressBar';
import useIndicator from '../../hooks/useIndicator';
import useAlert from '../../hooks/useAlert';
import useServerError from '../../hooks/useServerError';
import { sleep } from '../../utils/thread';

/**
 * データ入力画面カスタムHooks
 */
export const usePurchaseLocation = () => {
  const userCredential = useContext(AuthContext);
  const { isFinancialUser } = usePermission();
  const api = useApi();

  /** 処理タイプ */
  const ProcessType = Object.freeze({
    /** 初期化中 */
    Initializing: 0,
    /** 初期化済 */
    Initialized: 1,
    /** データロード後 */
    None: 2,
    /** アップロード中 */
    Uploading: 3,
    /** 解析中 */
    Analyzing: 4
  });
  const [process, setProcess] = useState(ProcessType.Initializing);

  const createLocationResponse = useRef(null);
  const analysisLocationResponse = useRef(null);

  const indicator = useIndicator();
  const progressBar = useProgressBar();
  const completePurchaseAlert = useAlert();
  const errorAlert = useAlert();
  const completeProgressAlert = useAlert();
  const serverError = useServerError();
  const [completeProgressMessage, setCompleteProgressMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isPolling = useRef(true);

  // セッション生成
  const createSession = async (params) => {
    let result;
    try {
      result = await api.createPurchasedSession(params);
    } catch {
      await serverError.open();
      return {
        error: true
      };
    }
    return result;
  };

  // アップロード実行
  const upload = async (params) => {
    indicator.loading(true);

    let result;
    try {
      // 拠点仮データ削除
      await api.deleteLocation({
        purchased_session: params.deleteSessionId
      });

      // 拠点仮データ登録
      result = await api.createLocation({
        user_permission: `${userCredential.user_permission}`,
        company_id: userCredential.company_id,
        company_name: userCredential.company_name,
        user_id: userCredential.user_id,
        family_name: userCredential.family_name,
        given_name: userCredential.given_name,
        purchased_session: params.sessionId,
        upload_file: params.csv
      });
    } catch {
      await serverError.open();
      return {
        error: true
      };
    } finally {
      indicator.loading(false);
    }

    if (result.pattern_insert === '0') {
      result.upload_sync_flg = true;
    }

    // エラーメッセージがある場合は表示して処理中断
    if (result.csv_err_message) {
      setErrorMessage(result.csv_err_message);
      await errorAlert.open();
      return {
        error: true
      };
    }

    createLocationResponse.current = result;

    return result;
  };

  // 再アップロード実行
  const reUpload = async (params) => {
    indicator.loading(true);

    let result;
    try {
      // 拠点仮データ修正
      result = await api.createFixedLocation({
        purchased_session: params.reUploadSessionId,
        upload_file: params.csv
      });
    } catch {
      await serverError.open();
      return {
        error: true
      };
    } finally {
      indicator.loading(false);
    }

    if (result.pattern_insert === '0') {
      result.upload_sync_flg = true;
    }

    // エラーメッセージがある場合は表示して処理中断
    if (result.csv_err_message) {
      setErrorMessage(result.csv_err_message);
      await errorAlert.open();
      return {
        error: true
      };
    }

    createLocationResponse.current = result;

    return result;
  };

  // アップロード処理を監視する
  const observeUploading = async (params) => {
    if (
      createLocationResponse.current &&
      createLocationResponse.current.pattern_insert &&
      createLocationResponse.current.pattern_insert === '0'
    ) {
      return await getPreviewData();
    }

    let response;

    progressBar.loading(true);

    while (isPolling.current) {
      response = await api.getInsertStatus({
        user_id: userCredential.user_id,
        company_id: userCredential.company_id
      });

      if (response.status !== '0') {
        break;
      }

      progressBar.setStatus(response);

      // 終わってなければ一定間隔で呼び出し継続
      // TODO: 呼び出す間隔は未決。決定次第修正
      await sleep(3000);
    }

    progressBar.loading(false);

    // ジョブ無しのため終了
    if (response.status === '-1') {
      return null;
    }

    if (response.flg_error === '1') {
      // 解析中に業務エラー発生
      setErrorMessage(response.message);
      await errorAlert.open();
      return null;
    }

    // 処理済み
    if (response.message) {
      setCompleteProgressMessage(response.message);
      await completeProgressAlert.open();
    }

    return await getPreviewData(response.purchased_session);
  };

  // プレビュー用データ取得
  const getPreviewData = async (sessionId) => {
    if (
      createLocationResponse.current &&
      createLocationResponse.current.pattern_insert &&
      createLocationResponse.current.pattern_insert === '0'
    ) {
      return createLocationResponse.current;
    }

    const response = await api.getPreviewData({ purchased_session: sessionId });
    response.purchased_session = sessionId;

    return response;
  };

  // 分析実行
  const analyze = async (params) => {
    indicator.loading(true);

    let result;
    try {
      // 拠点分析
      result = await api.analysisLocation({
        user_permission: `${userCredential.user_permission}`,
        user_id: userCredential.user_id,
        company_id: userCredential.company_id,
        purchased_session: params.sessionId,
        analysis_name: params.analysisName
      });
    } catch {
      await serverError.open();
      return {
        error: true
      };
    } finally {
      indicator.loading(false);
    }

    // エラーメッセージがある場合は表示して処理中断
    if (result.err_message) {
      setErrorMessage(result.err_message);
      await errorAlert.open();
      return { error: true };
    }

    analysisLocationResponse.current = result;

    return result;
  };

  // 分析処理を監視する
  const observeAnalyzing = async () => {
    // 結果がない(初期表示) or 結果があって非同期指定の場合処理する
    if (!analysisLocationResponse.current || analysisLocationResponse.current.pattern_analysis === '1') {
      let response;

      progressBar.loading(true);

      while (isPolling.current) {
        response = await api.getAnalysisStatus({
          user_id: userCredential.user_id,
          company_id: userCredential.company_id
        });

        if (response.status !== '0') {
          break;
        }

        progressBar.setStatus(response);

        // 終わってなければ一定間隔で呼び出し継続
        // TODO: 呼び出す間隔は未決。決定次第修正
        await sleep(3000);
      }

      progressBar.loading(false);

      // ジョブ無しのため終了
      if (response.status === '-1') {
        return false;
      }

      if (response.flg_error === '1') {
        // 解析中に業務エラー発生
        setErrorMessage(response.message);
        await errorAlert.open();
        return false;
      }

      // 処理済み
      if (response.message) {
        setCompleteProgressMessage(response.message);
        await completeProgressAlert.open();
        await api.updateJobStatus({
          purchased_session: response.purchased_session,
          job_code: '3' // 分析
        });
      }
      return true;
    }

    // 同期指定の場合の確認画面表示
    if (isFinancialUser() && analysisLocationResponse.current.pattern_analysis === '0') {
      setCompleteProgressMessage('データ分析が完了しました\nData analysis is completed');
      await completeProgressAlert.open();
      return true;
    }

    // メール送信：金融ユーザー以外送信する
    if (!isFinancialUser()) {
      try {
        await api.sendMail({
          company_id: userCredential.company_id,
          company_name: userCredential.company_name,
          user_id: userCredential.user_id,
          family_name: userCredential.family_name,
          given_name: userCredential.given_name,
          user_permission: userCredential.user_permission,
          admin_permission: userCredential.admin_permission,
          purchased_date: analysisLocationResponse.current.purchased_date,
          purchased_count: analysisLocationResponse.current.purchased_count,
          analysis_id: analysisLocationResponse.current.analysis_id
        });
      } catch {
        await serverError.open();
      }
      await completePurchaseAlert.open();
    }

    return true;
  };

  // 非同期処理APIの停止
  useEffect(() => {
    isPolling.current = true;
    return () => {
      isPolling.current = false;
    };
  }, []);

  return {
    ProcessType,
    process,
    setProcess,
    createSession,
    upload,
    reUpload,
    observeUploading,
    getPreviewData,
    analyze,
    observeAnalyzing,
    dialog: {
      Indicator: indicator.Indicator,
      CompletePurchaseAlert: completePurchaseAlert.Alert,
      ErrorAlert: errorAlert.Alert,
      completeProgressAlert: completeProgressAlert.Alert,
      ServerError: serverError.ServerError,
      ProgressBar: progressBar.ProgressBar,
      completeProgressMessage,
      errorMessage
    }
  };
};
