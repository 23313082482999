import { useState, useRef } from 'react';

const useMapboxModules = () => {
  const initOnceFlg = useRef(false);
  const [modules, setModules] = useState({});

  if (!initOnceFlg.current) {
    const modules = {};

    // ズームイン／ズームアウトボタン
    modules.navigationControl = {
      options: {
        showCompass: false
      },
      position: 'bottom-right'
    };

    // 帰属表示
    modules.attributionControl = {
      position: 'bottom-right'
    };
    setModules(modules);

    initOnceFlg.current = true;
  }
  return { ...modules };
};

export default useMapboxModules;
