import { useRef, useState } from "react";
import * as ReactDOM from 'react-dom';

const useAlert = () => {
  const [isOpen, setIsOpen] = useState(false);

  const resolve = useRef(null);

  const open = async (content) => {
    setIsOpen(true);

    return new Promise((res) => {
      resolve.current = res;
    })
  }

  const close = (result) => {
    resolve.current(result);
    setIsOpen(false);
  };

  const Alert = (props) => isOpen && 
    ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className="modal">
          <div className="modal-content">
            {props.children}
          </div>
          <div className="modal-footer">
            <a className="btn btn-primary rounded-pill mx-1" tabIndex="-1" onClick={() => close(true)}>
              OK
            </a>
          </div>
        </div>
      </div>,
    document.getElementById('modal-root') // モーダルを表示するDOMノード
  );

  return {
    Alert, open
  }
}

export default useAlert;