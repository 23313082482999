import React from "react";

const MultiLine = (props) => {
  return (
    <p>
      {props.text.split('\n').map((t, index) => (
        <React.Fragment key={index}>
          {t}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

export default MultiLine;