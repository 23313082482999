import { useRef, useState } from "react";
import { floor } from "../../../../utils/number";

import circle from "@turf/circle";
import { polygon } from "@turf/helpers";
import transformRotate from "@turf/transform-rotate";
import mapboxgl from "mapbox-gl";

import usePermission from '../../../../hooks/usePermission';

const useLocationEdit = () => {

  const [map, setMap] = useState();

  // 編集中の拠点情報
  const location = useRef(null);
  // 緯度経度(マーカー移動後の緯度経度を保持)
  const lngLat = useRef(null);
  // 編集前の緯度経度
  const beforeLngLat = useRef(null);
  // 拠点編集確定可能フラグ
  const [submittable, setSubmittable] = useState(false);

  const { isFinancialUser } = usePermission();

  // 初期化
  const init = (map) => {
    // 初期表示を日本語化
    map.on('load', () => {
      map.getStyle().layers.forEach(function (thisLayer) {
        if (thisLayer.id.indexOf('-label') > 0) {
          map.setLayoutProperty(thisLayer.id, 'text-field', ['get', 'name_ja']);
        }
      });
    });

    map.loadImage(`${process.env.PUBLIC_URL}/img/icon_map_marker.png`, (error, image) => {
      if (error) throw error;
      map.addImage('point-marker', image);
    });

    map.loadImage(`${process.env.PUBLIC_URL}/img/icon_map_marker_active.png`, (error, image) => {
      if (error) throw error;
      map.addImage('point-marker-active', image);
    });

    // 拠点マーカーのInfoWindow表示
    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point).filter((v) => v.properties.location_id);

      for (let f of features) {
        const lat = f.properties.location_lat;
        const lon = f.properties.location_lon;

        const html = `
          <div class='custom-infowindow'>
            <div>座標 lat, lon ${lat},${lon}</div>
            <div><strong>Location name ${f.properties.location_name}</strong></div>
          </div>
        `;

        new mapboxgl.Popup({ closeOnClick: true })
          .setLngLat(e.lngLat)
          .setHTML(html)
          .addTo(map)
          .setMaxWidth('fit-content');
      }
    });

    setMap(map);
  }

  // 拠点の設定
  const setLocations = (locations) => {
    location.current = null;
    lngLat.current = null;
    beforeLngLat.current = null;
    setSubmittable(false);

    // レイヤー／ソースを削除する
    if (map.getSource("point")) {
      map.removeLayer("point");
      map.removeLayer("point-drag");
      map.removeSource("point");
    }

    if (map.getSource("location-mesh")) {
      map.removeLayer("location-mesh-layer");
      map.removeSource("location-mesh")
    }

    // InfoWindowの削除
    removePopup();

    if (!locations) {
      return;
    }
    const geojson = {
      'type': 'FeatureCollection',
      'features': locations
        .map((v) => {
          // 緯度経度が存在しない場合は地図に追加しない
          if (!v.location_lon || !v.location_lat) {
            return null;
          }
          return {
            'type': 'Feature',
            'properties': {
              'location_id': v.location_id,
              'location_name': v.location_name,
              'location_lon': v.location_lon,
              'location_lat': v.location_lat,
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [v.location_lon, v.location_lat]
            }
          }
        })
        .filter((v) => v !== null)
    };

    map.addSource('point', {
      'type': 'geojson',
      'data': geojson
    });

    // ベースレイヤー
    map.addLayer({
      'id': 'point',
      'type': 'symbol',
      'source': 'point',
      'layout': {
        'icon-image': 'point-marker',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'bottom'
      }
    });

    // ドラッグ用レイヤー
    map.addLayer({
      'id': 'point-drag',
      'type': 'symbol',
      'source': 'point',
      'layout': {
        'icon-image': 'point-marker-active',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'icon-anchor': 'bottom',
      }
    });
    // ドラッグ用レイヤーの初期表示は非表示
    map.setFilter('point-drag',  ['==', ['get', 'location_id'], 0]);
  }

  // 編集開始
  const start = (newLocation) => {
    setSubmittable(false);

    // すでに編集中のマーカーがあれば初期状態に戻す
    if(location.current) {
      // マーカーの位置を戻す
      setLngLat(location.current.location_id, beforeLngLat.current);

      // マーカー色を戻す
      resetMarkerColor(map);

      location.current = null;
      lngLat.current = null;
      beforeLngLat.current = null;
    }

    // 編集対象の拠点情報を保持
    location.current = newLocation;

    // 編集対象の編集前緯度経度を保持
    beforeLngLat.current = {lng: location.current.location_lon, lat: location.current.location_lat};
    
    // ドラッグイベントの設定
    map.on('mousedown', 'point-drag', (e) => {
      e.preventDefault();

      // 対象が編集中のマーカーであるか確認
      const features = map.queryRenderedFeatures(e.point).filter(v => v.properties.location_id && v.properties.location_id === location.current.location_id)
      if(features && features.length === 0) {
        return;
      }

      map.on('mousemove', onMove);
      map.once('mouseup', onUp);
    });

    // マーカー色変更
    setMarkerActiveColor(map, location.current.location_id);

    // 地図移動
    map.flyTo({
      center: beforeLngLat.current,
      zoom: 13,
      speed: 2
    });

    if (!isFinancialUser()) {
      // マーカー周辺に四角形描画
      createSquare(map, beforeLngLat.current);
    }
  }

  // ドラッグ中イベント
  const onMove = (e) => {
    if (!location.current) {
      return;
    }

    // 緯度経度取得
    const lat = parseFloat(floor(e.lngLat.lat, 6));
    const lng = parseFloat(floor(e.lngLat.lng, 6));

    // 移動先の緯度経度を保持
    lngLat.current = {lat, lng};

    // マーカーの位置移動
    setLngLat(location.current.location_id, lngLat.current);
  };

  // ドラッグ後イベント
  const onUp = (e) => {
    if (lngLat.current) {
      if (!isFinancialUser()) {
        // マーカー周辺に四角形再描画
        createSquare(map,  e.lngLat)
      }

      setSubmittable(true);
    }

    map.off('mousemove', onMove);
    map.off('touchmove', onMove);
  };

  // 確定
  const submit = () => {
    // マーカー色戻す
    resetMarkerColor(map);

    if (!isFinancialUser()) {
      // 四角形削除
      removeSquare(map);
    }

    // 拠点情報を返却する
    const data = location.current;
    data.location_lat = parseFloat(floor(lngLat.current.lat, 6));
    data.location_lon = parseFloat(floor(lngLat.current.lng, 6));

    // 初期化
    location.current = null;
    lngLat.current = null;
    setSubmittable(false);

    return data;
  }

  // 緯度経度設定
  const setLngLat = (location_id, lngLat) => {
    const geojson = map.getSource('point')._data
    const feature = geojson.features.filter(v => v.properties.location_id && v.properties.location_id === location_id)[0]
    feature.properties.location_lat = lngLat.lat;
    feature.properties.location_lon = lngLat.lng;
    feature.geometry.coordinates = [lngLat.lng, lngLat.lat];
    map.getSource('point').setData(geojson);
  }

  return {
    init,
    setLocations,
    start,
    submit,
    submittable,
    setMap
  }
};

export default useLocationEdit;

// 四角形描画
const createSquare = (map, lngLat) => {
  removeSquare(map);

  // 1辺2kmの四角形を描画する
  map.addSource("location-mesh", {
    type: "geojson",
    data: getSquare(lngLat),
  });
  map.addLayer({
    id: "location-mesh-layer",
    type: "line",
    source: "location-mesh",
    paint: {
      "line-color": "#000000",
      "line-width": 2,
    },
  });
}

// 四角形除去
const removeSquare = (map) => {
  if (map.getSource("location-mesh")) {
    map.removeLayer("location-mesh-layer");
    map.removeSource("location-mesh");
  }
}

// 四角形GeoJSON生成
const getSquare = (lngLat) => {
  // 円の頂点を４つに設定した半径√2のひし形を作成
  // 45°傾け、正方形を作成

  // 中心点を設定
  const center = [lngLat.lng, lngLat.lat];
  // 半径をルート2に設定
  const radius = Math.sqrt(2);

  const options = { steps: 4, units: "kilometers" };
  const diamond = circle(center, radius, options);

  // ひし形の頂点４つを取得
  const coordinatesOnCircle = diamond.geometry.coordinates;

  // ひし形の頂点を取得し、四角形に変換
  const poly = polygon(coordinatesOnCircle);

  // ひし形を45°傾けた四角形の頂点をGeoJSON形式で取得
  const rotatedPoly = transformRotate(poly, 45, { pivot: center });

  return rotatedPoly;
};

// マーカーにアクティブカラー設定
const setMarkerActiveColor = (map, location_id) => {
  map.setFilter('point', ['!=', ['get', 'location_id'], location_id]);
  map.setFilter('point-drag', ['==', ['get', 'location_id'], location_id]);
};

// マーカーのカラーリセット
const resetMarkerColor = (map) => {
  map.setFilter('point',  ['!=', ['get', 'location_id'], 0]);
  map.setFilter('point-drag',  ['==', ['get', 'location_id'], 0]);
}

// InfoWindowの削除
const removePopup = () => {
  const popups = document.getElementsByClassName('mapboxgl-popup');
  if ( popups.length ) {
    const popupsArray = Array.from(popups);
    popupsArray.forEach((popup) => {
      popup.remove();
   });
  }
};