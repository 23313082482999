import UploadLocation from './components/UploadLocation/UploadLocation';
import DataPreviewArea from './components/DataPreviewArea/DataPreviewArea';
import ConfirmPurchase from './components/ConfirmPurchase/ConfirmPurchase';

import { Header, Footer } from '../../layouts/index';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import usePermission from '../../hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import MultiLine from '../../components/MultiLine';
import PageLoader from '../PageLoader/page-loader';
import { usePurchaseLocation } from './usePurchaseLocation';

/**
 * データ入力画面
 */
function PurchaseLocation() {
  const navigate = useNavigate();
  const userCredential = useContext(AuthContext);
  const { hasAccessToPurchaseLocation } = usePermission();
  const {
    ProcessType,
    process,
    setProcess,
    createSession,
    upload,
    reUpload,
    observeUploading,
    analyze,
    observeAnalyzing,
    dialog
  } = usePurchaseLocation();

  const [sessionId, setSessionId] = useState(null);
  const [reUploadSessionId, setReUploadSessionId] = useState(null);
  const [locations, setLocations] = useState(null);
  const [price, setPrice] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorGeocode, setErrorGeocode] = useState(null);
  const [uploadSyncFlg, setUploadSyncFlg] = useState(null);
  const [countAddedLocation, setCountAddedLocation] = useState(null);

  const uploadingObservable = process === ProcessType.Initialized || process === ProcessType.Uploading;
  const analyzingObservable = process === ProcessType.Initialized || process === ProcessType.Analyzing;

  // 初期表示
  useEffect(() => {
    // セッションIDを発行
    (async () => {
      const params = {
        user_id: userCredential.user_id
      };
      const result = await createSession(params);
      if (result.error) {
        // セッションID発行失敗時は処理継続不可のためハザードマップ画面へ戻す
        navigate('/');
      }

      setSessionId(result.purchased_session);
      setProcess(ProcessType.Initialized);
    })();
  }, []);

  useEffect(() => {
    // 画面初期化中、データロード後は処理しない
    if (process === ProcessType.Initializing || process === ProcessType.None) {
      return;
    }

    (async () => {
      if (uploadingObservable) {
        const previewData = await observeUploading();
        setLocations(previewData ? previewData.locations : null);
        setPrice(previewData ? previewData.price : null);
        setEndDate(previewData ? previewData.end_date : null);
        setErrorGeocode(previewData ? previewData.count_error_geocoding : null);
        setReUploadSessionId(previewData ? previewData.purchased_session : null);
        setCountAddedLocation(previewData ? previewData.count_ok : null);
        setUploadSyncFlg(previewData ? previewData.upload_sync_flg : null);
      }

      if (analyzingObservable) {
        if (await observeAnalyzing()) {
          // ハザードマップ画面へ遷移
          navigate('/');
        }
      }

      setProcess(ProcessType.None);
    })();
  }, [process]);

  // 拠点情報CSVファイルアップロード後
  const handleChangeUploadLocation = async (e) => {
    const deleteSessionId = reUploadSessionId ? reUploadSessionId : sessionId;
    const result = await upload({
      deleteSessionId: deleteSessionId,
      sessionId: sessionId,
      csv: e.csv
    });

    // エラー発生の場合、各種stateをリセットする。
    if (result.error) {
      setLocations(null);
      setPrice(null);
      setEndDate(null);
      setErrorGeocode(null);
      setReUploadSessionId(null);
      setCountAddedLocation(null);
      setUploadSyncFlg(null);
      return;
    }

    setProcess(ProcessType.Uploading);
  };

  // 拠点位置修正後
  const handleChangeLocation = (e) => {
    const i = locations.findIndex((v) => v.location_id === e.location_id);
    const new_locations = [...locations];
    new_locations[i] = e;
    setLocations(new_locations);
  };

  // 分析実行
  const handleAnalyze = async (e) => {
    const uploadsessionId = reUploadSessionId ? reUploadSessionId : sessionId;
    const result = await analyze({
      sessionId: uploadsessionId,
      analysisName: e.analysisName
    });

    if (result.error) {
      return;
    }

    setProcess(ProcessType.Analyzing);
  };

  // ジオコーディングエラー修正CSV再アップロード後
  const handleReUploadLocation = async (e) => {
    const result = await reUpload({
      reUploadSessionId: reUploadSessionId,
      csv: e.csv
    });

    if (result.error) return;

    setProcess(ProcessType.Uploading);
  };

  // 有償(一般)、金融(一般)は遷移不可
  if (!hasAccessToPurchaseLocation()) {
    navigate('/');
    return;
  }

  return process === ProcessType.Initializing ? (
    <div className="page-layout">
      <PageLoader />
    </div>
  ) : (
    <div>
      <Header />
      <main className="main">
        <div className="container">
          <UploadLocation onChange={handleChangeUploadLocation} />
          <DataPreviewArea
            sessionId={sessionId}
            reUploadSessionId={reUploadSessionId}
            locations={locations}
            price={price}
            endDate={endDate}
            errorGeocode={errorGeocode}
            setErrorGeocode={setErrorGeocode}
            changeLocation={handleChangeLocation}
            reUploadLocation={handleReUploadLocation}
          />
          <ConfirmPurchase
            locations={locations}
            onAnalyze={handleAnalyze}
            uploadSyncFlg={uploadSyncFlg}
            countAddedLocation={countAddedLocation}
          />
        </div>
        <dialog.Indicator>
          <div>
            <p>Please wait for a while ...</p>
          </div>
        </dialog.Indicator>
        <dialog.CompletePurchaseAlert>
          <div>
            <p>
              ありがとうございます。
              <br />
              初回購入時は、以下の手続きを経て、2～3営業日程度で利用開始となります。
              <br />
              　(1)弊社より、契約申込書を送付
              <br />
              　(2)お客さまより、申込書返送
              <br />
              　(3)弊社による確認、承認
              <br />
              その後、担当者よりご請求書を送付させていただきます。
              <br />
            </p>
            <p>
              Thank you. <br />
              You can use this service after our approval if you purchase the premium hazard map for the first time.
              <br />
            </p>
          </div>
        </dialog.CompletePurchaseAlert>
        <dialog.completeProgressAlert>
          <MultiLine text={dialog.completeProgressMessage} />
        </dialog.completeProgressAlert>
        <dialog.ErrorAlert>
          <div>
            <MultiLine text={dialog.errorMessage} />
          </div>
        </dialog.ErrorAlert>
        <dialog.ServerError />
        <dialog.ProgressBar />
      </main>
      <Footer />
    </div>
  );
}

export default PurchaseLocation;
