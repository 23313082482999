import { useState } from 'react';

// 凡例パネルの作成
function LegendPanel() {
  const [showPanel, setShowPanel] = useState(true);
  const panelToggle = () => {
    setShowPanel(!showPanel);
  };
  return (
    <div className={'panel panel-bottom-right panel-legend' + (showPanel ? '' : ' hide')}>
      <button type="button" className="btn btn-panel-toggle" onClick={panelToggle}></button>
      <div className="flex-fill card border-0">
        <div className="card-header bg-transparent border-bottom-0">
          <div className="small lh-sm text-center">
            <div>浸水深:&nbsp;m</div>
            <div>(Depth:&nbsp;m)</div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-row justify-content-center gap-1">
            <div className="legend-flood-depth">
              <span>&gt;10</span>
              <span>&gt;0</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-fill card border-0">
        <div className="card-header bg-transparent border-bottom-0">
          <div className="small lh-sm text-center">
            <div>洪水頻度変化:&nbsp;年</div>
            <div>(Frequency:&nbsp;yrs)</div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex flex-row justify-content-center gap-1">
            <div className="legend-frequency">
              <span>&gt;1000</span>
              <span>500-1000</span>
              <span>250-500</span>
              <span>125-250</span>
              <span>105-125</span>
              <span>95-105</span>
              <span>75-95</span>
              <span>50-75</span>
              <span>25-50</span>
              <span>5-25</span>
              <span>0-5</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegendPanel;
