import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import React from 'react';
import usePermission from '../../../hooks/usePermission';

const HeaderButton = (props) => {
  const pathName = useLocation().pathname;
  const { hasAccessToPurchaseLocation, isFreeUser, isPremiumUser, isFinancialUser } = usePermission();

  const [expirationText, setExpirationText] = useState('');

  const [hasLocation, setHasLocation] = useState(false);

  const { logout } = useAuth0();

  useEffect(() => {
    if (!props.userInfo) return;
    const userInfo = props.userInfo;

    setHasLocation(userInfo.has_location);
    if (userInfo.expired_message) {
      setExpirationText(userInfo.expired_message);
    } else {
      setExpirationText('ご利用期限 Expiration Date\n' + userInfo.end_date + ' (JST)');
    }
  }, [props.userInfo]);

  const handleLogout = (e) => {
    e.preventDefault();
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL
      }
    });
  };

  // ハザードマップ画面のヘッダー
  const createHazardMapHeader = (reload) => {
    // 無償ユーザー：Go to Premium, 有償ユーザー：Location Import, 金融ユーザー：Analysis
    let locationButton = 'Location import';
    if (isFreeUser()) {
      locationButton = 'Go to Premium';
    } else if (isPremiumUser()) {
      locationButton = 'Location import';
    } else if (isFinancialUser()) {
      locationButton = 'Analysis';
    }

    let expirationHtml = <></>;

    // 拠点の有効期限表示
    if (hasLocation && !isFinancialUser()) {
      expirationHtml = (
        <div className="expiration">
          <span className="m-2">
            {expirationText.split('\n').map((t, index) => (
              <React.Fragment key={index}>
                {t}
                <br />
              </React.Fragment>
            ))}
          </span>
        </div>
      );
    }

    return (
      <>
        {expirationHtml}
        {hasAccessToPurchaseLocation() && (
          <Link to="/purchaseLocation" className="btn btn-primary rounded-pill pe-3">
            <span className={'icon ' + (isFinancialUser() ? 'icon-analysis' : 'icon-cart')}></span>
            {locationButton}
          </Link>
        )}
        <Link
          to="/analysisResult"
          className={'btn btn-primary rounded-pill pe-3' + (hasLocation ? '' : ' disabled')}
          tabIndex="-1">
          <span className="icon icon-search"></span>Result
        </Link>
        <Link to="/" className="btn btn-menu btn-menu-logout" title="ログアウト logout" onClick={reload}></Link>
      </>
    );
  };

  // 拠点入力画面のヘッダー
  const createLocationHeader = (reload) => {
    return (
      <>
        <Link to="/" className="btn btn-menu btn-menu-back" title="戻る back"></Link>
        <Link to="/" className="btn btn-menu btn-menu-logout" title="ログアウト logout" onClick={reload}></Link>
      </>
    );
  };

  // 分析結果確認画面のヘッダー
  const createResultHeader = (reload) => {
    return (
      <>
        <Link to="/" className="btn btn-menu btn-menu-back" title="戻る back"></Link>
        <Link to="/" className="btn btn-menu btn-menu-logout" title="ログアウト logout" onClick={reload}></Link>
      </>
    );
  };

  // 表示するhtmlを生成
  let headerButtonHtml = <></>;
  // ハザードマップ画面のヘッダー
  if (pathName === '/') headerButtonHtml = createHazardMapHeader(handleLogout);
  // 拠点入力画面のヘッダー
  if (pathName === '/purchaseLocation') headerButtonHtml = createLocationHeader(handleLogout);
  // 分析結果確認画面のヘッダー
  if (pathName === '/analysisResult') headerButtonHtml = createResultHeader(handleLogout);

  return (
    <div className="header-right">
      <div className="menu">{headerButtonHtml}</div>
    </div>
  );
};

export default HeaderButton;
