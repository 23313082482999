import usePermission from "../../../../hooks/usePermission";
import { toStringWithComma } from "../../../../utils/string";


function ResultTable(props) {

  const { isPremiumUser, isFinancialUser } = usePermission();

  const createSspPremium = (header1, table1, header2, table2) => {
    return(
      <>
        { /* 浸水深・被害額・影響日数 */ }
        <div className="card mb-3">
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-body-striped">
                <colgroup>
                  <col />
                  <col style={{ width: '50px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                </colgroup>
                {header1}
                {table1}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}

        { /* 期待被害額・期待影響日数 */ }
        <div className="card mb-3 ms-auto" style={{ width: 'calc(186px + 100px * 4 + 75px * 4 + 1rem + 4px)' }}>
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-striped">
                <colgroup>
                  <col />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '100px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                  <col style={{ width: '75px' }} />
                </colgroup>
                {header2}
                {table2}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}
      </>
    )
  };

  const createSspFinancial = (header1, table1, header2, table2) => {
    return(
      <>
        { /* 浸水深・被害額・影響日数 */ }
        <div className="card mb-3">
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-body-striped">
                <colgroup>
                  <col style={{width: '50px'}}/>
                  <col />
                  <col style={{width: '50px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                </colgroup>
                {header1}
                {table1}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}

        { /* 期待被害額・期待影響日数 */ }
        <div className="card mb-3 ms-auto" style={{width: 'calc(186px + 100px * 4 + 75px * 4 + 1rem + 4px)'}}>
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-striped">
                <colgroup>
                  <col style={{width: '50px'}}/>
                  <col />
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '100px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                  <col style={{width: '75px'}}/>
                </colgroup>
                {header2}
                {table2}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}
      </>
    )
  };

  const createSspHeader1 = () => {
    return(
      <>
          <thead className="text-center">
            <tr>
              {
                isFinancialUser() && (
                  <th rowSpan="2" className="bg-primary text-white">Group <br/>ID</th>
                )
              }
              <th rowSpan="2" className="bg-primary text-white">
                Location Name
              </th>
              <th rowSpan="2" className="bg-primary text-white">
                RP
                <br />
                (yrs)
              </th>
              <th colSpan="4" className="bg-primary text-white">
                <div className="d-flex align-items-center">
                  <div>
                    浸水深<span className="ms-2">Inundation depth</span>
                  </div>
                  <div className="ms-auto">(m)</div>
                </div>
              </th>
              <th colSpan="4" className="bg-primary text-white">
                <div className="d-flex align-items-center">
                  <div>
                    被害額<span className="ms-2">Estimated loss</span>
                  </div>
                </div>
              </th>
              <th colSpan="4" className="bg-primary text-white">
                <div className="d-flex align-items-center">
                  <div>
                    影響日数<span className="ms-2">Affected days</span>
                  </div>
                  <div className="ms-auto">(days)</div>
                </div>
              </th>
            </tr>
            <tr className="text-center">
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2030</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2050</th>
              <th className="bg-skyblue fw-normal">2080</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2030</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2050</th>
              <th className="bg-skyblue fw-normal">2080</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2030</th>
              <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2050</th>
              <th className="bg-skyblue fw-normal">2080</th>
            </tr>
          </thead>
      </>
    )
  }

  const createSspTable1 = (scenario, result) => {
    return (
      <>
        {
          result &&
          result.map((r, i) => {
            return(
              <tbody className="text-end">
                <tr>
                  {
                    isFinancialUser() && (
                      <td rowSpan="4" className="text-center">{ r.group_id }</td>
                    )
                  }
                  <td rowSpan="4" className="text-start">
                    { r.location_name }
                  </td>
                  <td className="bs-bottom-dashed bw-bottom-1">100</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp100_depth, 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp100_depth`], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp100_depth`], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp100_depth`], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r.historical_rp100_damage) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2030_rp100_damage`]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2050_rp100_damage`]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2080_rp100_damage`]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp100_affected_day, 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp100_affected_day`], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp100_affected_day`], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp100_affected_day`], 1) }</td>
                </tr>
                <tr>
                  <td className="bs-bottom-dashed bw-bottom-1">200</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp200_depth, 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp200_depth`], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp200_depth`], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp200_depth`], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r.historical_rp200_damage) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2030_rp200_damage`]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2050_rp200_damage`]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2080_rp200_damage`]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp200_affected_day, 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp200_affected_day`], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp200_affected_day`], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp200_affected_day`], 1) }</td>
                </tr>
                <tr>
                  <td className="bs-bottom-dashed bw-bottom-1">500</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp500_depth, 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp500_depth`], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp500_depth`], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp500_depth`], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r.historical_rp500_damage) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2030_rp500_damage`]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2050_rp500_damage`]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2080_rp500_damage`]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r.historical_rp500_affected_day, 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2030_rp500_affected_day`], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2050_rp500_affected_day`], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r[`2080_rp500_affected_day`], 1) }</td>
                </tr>
                <tr>
                  <td>1000</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r.historical_rp1000_depth, 2) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2030_rp1000_depth`], 2) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2050_rp1000_depth`], 2) }</td>
                  <td>{ toStringWithComma(r[`2080_rp1000_depth`], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r.historical_rp1000_damage) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2030_rp1000_damage`]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2050_rp1000_damage`]) }</td>
                  <td className={`${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2080_rp1000_damage`]) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r.historical_rp1000_affected_day, 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2030_rp1000_affected_day`], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2050_rp1000_affected_day`], 1) }</td>
                  <td>{ toStringWithComma(r[`2080_rp1000_affected_day`], 1) }</td>
                </tr>
              </tbody>
            );
          })
        }
      </>
    )
  };

  const createSspHeader2 = (result) => {
    return(
      <>
        <thead className="text-center">
          <tr>
            {
              isFinancialUser() && (
                <th rowSpan="2" className="bg-primary text-white">Group <br/>ID</th>
              )
            }
            <th rowSpan="2" className="bg-primary text-white ">
              Location
            </th>
            <th colSpan="4" className="bg-primary text-white ">
              <div className="d-flex align-items-center">
                <div>
                  期待被害額<span className="ms-2">Average annual loss</span>
                </div>
              </div>
            </th>
            <th colSpan="4" className="bg-primary text-white ">
              <div className="d-flex align-items-center">
                <div>
                  期待影響日数<span className="ms-2">Average affected days</span>
                </div>
                <div className="ms-auto">(days)</div>
              </div>
            </th>
          </tr>
          <tr>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2030</th>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2050</th>
            <th className="bg-skyblue fw-normal">2080</th>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2030</th>
            <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2050</th>
            <th className="bg-skyblue fw-normal">2080</th>
          </tr>
        </thead>
      </>
    );
  }

  const createSspTable2 = (scenario, result) => {
    return (
      <>
        <tbody className="text-end">
        {
          result &&
          result.map((r, i) => {
            return(
              <tr>
                {
                  isFinancialUser() && (
                    <td className="text-center">{ r.group_id }</td>
                  )
                }
                <td className="text-start">{ r.location_name }</td>
                <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r.historical_damage_average) }</td>
                <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2030_damage_average`]) }</td>
                <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2050_damage_average`]) }</td>
                <td className={`${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r[`2080_damage_average`]) }</td>
                <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r.historical_affected_day_average, 1) }</td>
                <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2030_affected_day_average`], 1) }</td>
                <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r[`2050_affected_day_average`], 1) }</td>
                <td>{ toStringWithComma(r[`2080_affected_day_average`], 1) }</td>
              </tr>

            )  
          })
        }
        </tbody>
      </>
    )
  };

  const createXtempFinancial = (header1, table1, header2, table2) => {
    return(
      <>
        { /* 浸水深・被害額・影響日数 */ }
        <div className="card mb-3">
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-body-striped">
                <colgroup>
                  <col style={{width: '50px'}}/>
                  <col />
                  <col style={{width: '50px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '80px'}}/>
                  <col style={{width: '80px'}}/>
                  <col style={{width: '80px'}}/>
                  <col style={{width: '80px'}}/>
                  <col style={{width: '80px'}}/>                            
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                  <col style={{width: '65px'}}/>
                </colgroup>
                {header1}
                {table1}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}

        { /* 期待被害額・期待影響日数 */ }
        <div className="card mb-3 ms-auto" style={{width: 'calc(50px + 86px + 80px * 5 + 65px * 5 + 1rem + 4px)'}}>
          <div className="card-body">
            <div className="table-scroll bw-top-2 bw-start-2 border-darkgray">
              <table className="table table-style2 table-sm table-striped">
                <colgroup>
                  <col style={{width: '50px'}}/>
                    <col />
                    <col style={{width: '80px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '80px'}}/>
                    <col style={{width: '65px'}}/>
                    <col style={{width: '65px'}}/>
                    <col style={{width: '65px'}}/>
                    <col style={{width: '65px'}}/>
                    <col style={{width: '65px'}}/>
                  </colgroup>
                {header2}
                {table2}
              </table>
            </div>
          </div>
        </div>
        { /* 初期状態の余白 */ }
        { (Object.keys(props.result).length === 0) ? <div className="pb-5"/> : null}
      </>
    )
  };

  const createXtempHeader1 = () => {
    return(
      <>
        <thead className="text-center">
            <tr>
                <th rowSpan="2" className="bg-primary text-white">Group <br/>ID</th>
                <th rowSpan="2" className="bg-primary text-white">Location Name</th>
                <th rowSpan="2" className="bg-primary text-white">RP<br/>(yrs)</th>
                <th colSpan="5" className="bg-primary text-white">
                    <div className="d-flex align-items-center">
                        <div>浸水深<span className="ms-2">Inundation depth</span></div>
                        <div className="ms-auto">(m)</div>
                    </div>
                </th>
                <th colSpan="5" className="bg-primary text-white">
                    <div className="d-flex align-items-center">
                        <div>被害額<span className="ms-2">Estimated loss</span></div>
                    </div>
                </th>
                <th colSpan="5" className="bg-primary text-white">
                    <div className="d-flex align-items-center">
                        <div>影響日数<span className="ms-2">Affected days</span></div>
                        <div className="ms-auto">(days)</div>
                    </div>
                </th>
            </tr>
            <tr className="text-center">
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">1.5°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">3.0°C</th>
                <th className="bg-skyblue fw-normal">4.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">1.5°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">3.0°C</th>
                <th className="bg-skyblue fw-normal">4.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">1.5°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">3.0°C</th>
                <th className="bg-skyblue fw-normal">4.0°C</th>
            </tr>
        </thead>
      </>
    )
  }

  const createXtempTable1 = (scenario, result) => {
    return (
      <>
        {
          result &&
          result.map((r, i) => {
            return(
              <tbody className="text-end">
                <tr>
                  <td rowSpan="4" className="text-center">{ r.group_id }</td>
                  <td rowSpan="4" className="text-start">{ r.location_name }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">100</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp100_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp100_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp100_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp100_depth"], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp100_depth"], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["historical_rp100_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_1_5_rp100_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_2_0_rp100_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_3_0_rp100_damage"]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_4_0_rp100_damage"]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp100_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp100_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp100_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp100_affected_day"], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp100_affected_day"], 1) }</td>
                </tr>
                <tr>
                  <td className="bs-bottom-dashed bw-bottom-1">200</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp200_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp200_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp200_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp200_depth"], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp200_depth"], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["historical_rp200_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_1_5_rp200_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_2_0_rp200_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_3_0_rp200_damage"]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_4_0_rp200_damage"]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp200_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp200_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp200_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp200_affected_day"], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp200_affected_day"], 1) }</td>
                </tr>
                <tr>
                  <td className="bs-bottom-dashed bw-bottom-1">500</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp500_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp500_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp500_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp500_depth"], 2) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp500_depth"], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["historical_rp500_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_1_5_rp500_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_2_0_rp500_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_3_0_rp500_damage"]) }</td>
                  <td className={`bs-bottom-dashed bw-bottom-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_4_0_rp500_damage"]) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["historical_rp500_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_1_5_rp500_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_2_0_rp500_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1 bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_3_0_rp500_affected_day"], 1) }</td>
                  <td className="bs-bottom-dashed bw-bottom-1">{ toStringWithComma(r["temp_4_0_rp500_affected_day"], 1) }</td>
                </tr>
                <tr>
                  <td>1000</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["historical_rp1000_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_1_5_rp1000_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_2_0_rp1000_depth"], 2) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_3_0_rp1000_depth"], 2) }</td>
                  <td>{ toStringWithComma(r["temp_4_0_rp1000_depth"], 2) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["historical_rp1000_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_1_5_rp1000_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_2_0_rp1000_damage"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_3_0_rp1000_damage"]) }</td>
                  <td className={`${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_4_0_rp1000_damage"]) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["historical_rp1000_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_1_5_rp1000_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_2_0_rp1000_affected_day"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_3_0_rp1000_affected_day"], 1) }</td>
                  <td>{ toStringWithComma(r["temp_4_0_rp1000_affected_day"], 1) }</td>
                </tr>
              </tbody>
            );
          })
        }
      </>
    )
  };

  const createXtempHeader2 = (result) => {
    return(
      <>
        <thead className="text-center">
            <tr>
                <th rowSpan="2" className="bg-primary text-white">Group <br/>ID</th>
                <th rowSpan="2" className="bg-primary text-white ">Location</th>
                <th colSpan="5" className="bg-primary text-white ">
                    <div className="d-flex align-items-center">
                        <div>期待被害額<span className="ms-2">Average annual loss</span></div>
                    </div>
                </th>
                <th colSpan="5" className="bg-primary text-white ">
                    <div className="d-flex align-items-center">
                        <div>期待影響日数<span className="ms-2">Average affected days</span></div>
                        <div className="ms-auto">(days)</div>
                    </div>
                </th>
            </tr>
            <tr>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">1.5°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">3.0°C</th>
                <th className="bg-skyblue fw-normal">4.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2020</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">1.5°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">2.0°C</th>
                <th className="bg-skyblue fw-normal bs-end-dashed bw-end-1">3.0°C</th>
                <th className="bg-skyblue fw-normal">4.0°C</th>
            </tr>
        </thead>
      </>
    );
  }

  const createXtempTable2 = (scenario, result) => {
    return (
      <>
        <tbody className="text-end">
        {
          result &&
          result.map((r, i) => {
            return(
              <tr>
                  <td className="text-center">{ r.group_id }</td>
                  <td className="text-start">{ r.location_name }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["historical_damage_average"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_1_5_damage_average"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_2_0_damage_average"]) }</td>
                  <td className={`bs-end-dashed bw-end-1 ${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_3_0_damage_average"]) }</td>
                  <td className={`${ i % 2 === 0 ? 'bg-lightgray': ''}`}>{ toStringWithComma(r["temp_4_0_damage_average"]) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["historical_affected_day_average"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_1_5_affected_day_average"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_2_0_affected_day_average"], 1) }</td>
                  <td className="bs-end-dashed bw-end-1">{ toStringWithComma(r["temp_3_0_affected_day_average"], 1) }</td>
                  <td>{ toStringWithComma(r["temp_4_0_affected_day_average"], 1) }</td>
              </tr>
            )  
          })
        }
        </tbody>
      </>
    )
  };

  let header1;
  let table1;
  let header2;
  let table2;

  // SSPとX℃riseでテーブルは分けて生成する。
  switch(props.scenario){
    case 'ssp126':
    case 'ssp585':
      header1 = createSspHeader1();
      table1 = createSspTable1(props.scenario, props.result[props.scenario]);
      header2 = createSspHeader2();
      table2 = createSspTable2(props.scenario, props.result[props.scenario]);

      if(isPremiumUser()){
        return (
          createSspPremium(header1, table1, header2, table2)
        );
      } else if(isFinancialUser()) {
        return (
          createSspFinancial(header1, table1, header2, table2)
        );
      }

      return null;
      
    default:
      header1 = createXtempHeader1();
      table1 = createXtempTable1(props.scenario, props.result[props.scenario]);
      header2 = createXtempHeader2();
      table2 = createXtempTable2(props.scenario, props.result[props.scenario]);
      return (
        createXtempFinancial(header1, table1, header2, table2)
      );
  }

}

export default ResultTable;
