import { useLocation } from 'react-router-dom';
import { FooterCopyright, FooterDocument } from './components/index';

const Footer = () => {
  const pathName = useLocation().pathname;
  return (
    <footer className="footer">
      <div className={pathName === '/' ? 'container-fluid' : 'container'}>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <FooterDocument />
          <FooterCopyright />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
