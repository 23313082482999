import { useEffect, useState } from 'react';

import PanelHazardMap from './PanelHazardMap';
import PanelFloodPrediction from './PanelFloodPrediction';
import PanelHazardMapHiRes from './PanelHazardMapHiRes';
import PanelLocationToggle from './PanelLocationToggle';
import PanelFinancialHiRes from './PanelFinancialHiRes';

import useSelectLayer from './useSelectLayer';

import useAlert from '../../../../hooks/useAlert';
import usePermission from '../../../../hooks/usePermission';

const DataLayerPanel = (props) => {
  const alert = useAlert();
  const { isFinancialUser } = usePermission();

  // 表示するレイヤーIDの生成
  const [dispLayer, setLayersList, firstHiResLayerFlg] = useSelectLayer(props.setFirstHiResLayer);

  useEffect(() => {
    props.setDispLayerList(dispLayer);
  }, [dispLayer]);

  // パネル開閉
  const [showPanel, setShowPanel] = useState(true);
  const panelToggle = () => {
    setShowPanel(!showPanel);
  };

  const panelHtml = isFinancialUser() ? (
    // 金融ユーザー
    <div className={'panel panel-top-left panel-map' + (showPanel ? '' : ' hide')}>
      <button type="button" className="btn btn-panel-toggle" onClick={panelToggle} />
      <div className="panel-map-body">
        <PanelFinancialHiRes setLayersList={setLayersList} open={alert.open} firstHiResLayerFlg={firstHiResLayerFlg} />
        <PanelFloodPrediction setLayersList={setLayersList} open={alert.open} />
        <PanelLocationToggle setVisible={props.setVisible} userInfo={props.userInfo} />
      </div>
    </div>
  ) : (
    // 一般ユーザー
    <div className={'panel panel-top-left panel-map' + (showPanel ? '' : ' hide')}>
      <button type="button" className="btn btn-panel-toggle" onClick={panelToggle} />
      <div className="panel-map-body">
        <PanelHazardMap setLayersList={setLayersList} open={alert.open} />
        <PanelFloodPrediction setLayersList={setLayersList} open={alert.open} />
        <PanelHazardMapHiRes setLayersList={setLayersList} open={alert.open} firstHiResLayerFlg={firstHiResLayerFlg} />
        <PanelLocationToggle setVisible={props.setVisible} userInfo={props.userInfo} />
      </div>
    </div>
  );

  return (
    <>
      {panelHtml}
      <alert.Alert>
        <div>
          <p>
            レイヤーは5個まで表示できます
            <br />
            Up to 5 layers can be displayed
          </p>
        </div>
      </alert.Alert>
    </>
  );
};

export default DataLayerPanel;
