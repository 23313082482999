import mapboxgl from 'mapbox-gl';
import { useState, useRef, useEffect } from 'react';

import { floor } from '../../../utils/number';
import useTilesets from './useTilesets';

import usePermission from '../../../hooks/usePermission';

// InfoWindow表示処理
const useInfoWindow = (geojson) => {
  const { isFinancialUser } = usePermission();

  const [layerIds, setLayerIds] = useState({});
  const map = useRef(null);

  const {
    createHazardMap,
    createHazardMapLoRes1,
    createHazardMapLoRes2,
    createFloodPrediction,
    createHazardMapHiRes,
    createLocationLayer
  } = useTilesets();

  const infoWindowEvent = useRef(null);

  // 初期化
  const init = (_map) => {
    map.current = _map;
    const layerIds = createInfoWindowTitle(
      createHazardMap().layerIds,
      createHazardMapLoRes1().layerIds,
      createHazardMapLoRes2().layerIds,
      createFloodPrediction().layerIds,
      createHazardMapHiRes().layerIds,
      createLocationLayer().layerId,
      isFinancialUser()
    );
    setLayerIds(layerIds);
  };

  useEffect(() => {
    if (!map.current || !Object.keys(layerIds).length) return;

    if (infoWindowEvent.current) {
      map.current.off('click', infoWindowEvent.current);
    }
    infoWindowEvent.current = infoWindow;
    map.current.on('click', infoWindowEvent.current);
  }, [layerIds, geojson]);

  // InfoWindowの作成
  const infoWindow = (e) => {
    // InfoWindowを表示する座標
    let popUpCoordinates = [];

    // infoWindowの中身
    let infoWindowContent = '';

    const features = map.current.queryRenderedFeatures(e.point);
    const clickLayerIds = [];

    let infoWindowFeatures = [];
    for (let i = 0; i < features.length; i++) {
      const overlayId = features[i].layer.id;
      if (layerIds[overlayId]) {
        infoWindowFeatures.push(features[i]);
        clickLayerIds.push(overlayId);
      }
    }
    infoWindowFeatures.reverse();

    // クリックした地点にレイヤーが存在しない場合
    if (!infoWindowFeatures.length) {
      return;
    }

    // 各レイヤーID
    const locationLayerId = createLocationLayer().layerId;
    const hazardMapLoRes1IdPrefix = createHazardMapLoRes1().layerIdPrefix;
    const hazardMapLoRes2IdPrefix = createHazardMapLoRes2().layerIdPrefix;
    const hazardMapLayerIdPrefix = createHazardMap().layerIdPrefix;
    const FloodPredictionLayerIdPrefix = createFloodPrediction().layerIdPrefix;
    const HazardMapHiResLayerIdPrefix = createHazardMapHiRes().layerIdPrefix;

    // InfoWindowを表示する緯度経度を取得
    if (clickLayerIds.includes(locationLayerId)) {
      const locationfeatures = infoWindowFeatures.filter((item) => item.layer.id === locationLayerId);
      const clickedLocation = geojson.features.filter(
        (feature) =>
          feature.properties.analysis_id === locationfeatures[0].properties.analysis_id &&
          feature.properties.location_id === locationfeatures[0].properties.location_id
      );
      popUpCoordinates = clickedLocation[0].geometry.coordinates;
    } else {
      const lng = floor(e.lngLat.lng, 6);
      const lat = floor(e.lngLat.lat, 6);
      popUpCoordinates = [lng, lat];
    }

    // 詳細情報
    infoWindowContent += `
        <div class='custom-infowindow-item'>
          <div><strong>詳細情報 Information</strong></div>
          <div>座標 lat, lon ${popUpCoordinates[1]},${popUpCoordinates[0]}</div>
        </div>
      `;

    // InfoWindow内に追加済みのレイヤーID
    const currentLayer = [];

    if (!isFinancialUser()) {
      // 一般版ユーザーのInfoWindow作成
      // 拠点情報 > (ハザードマップ(500m mesh) or 2km mesh 固定メッセージ) > 洪水頻度変化 > ハザードマップ(90m mesh)
      infoWindowContent += createInfoWindowLocation(clickLayerIds, locationLayerId, infoWindowFeatures);
      infoWindowContent += createInfoWindowHazardMap(
        infoWindowFeatures,
        hazardMapLayerIdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapLoRes1(
        infoWindowFeatures,
        hazardMapLoRes1IdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapLoRes2(
        infoWindowFeatures,
        hazardMapLoRes2IdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowFloodPrediction(
        infoWindowFeatures,
        FloodPredictionLayerIdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapHiRes(
        infoWindowFeatures,
        HazardMapHiResLayerIdPrefix,
        layerIds,
        currentLayer
      );
    } else {
      // 金融版ユーザーのInfoWindow作成
      // 拠点情報 > (ハザードマップ(500m mesh, 90m mesh) or 2km mesh 固定メッセージ) > 洪水頻度変化
      infoWindowContent += createInfoWindowLocation(clickLayerIds, locationLayerId, infoWindowFeatures);
      infoWindowContent += createInfoWindowHazardMap(
        infoWindowFeatures,
        hazardMapLayerIdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapHiRes(
        infoWindowFeatures,
        HazardMapHiResLayerIdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapLoRes1(
        infoWindowFeatures,
        hazardMapLoRes1IdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowHazardMapLoRes2(
        infoWindowFeatures,
        hazardMapLoRes2IdPrefix,
        layerIds,
        currentLayer
      );
      infoWindowContent += createInfoWindowFloodPrediction(
        infoWindowFeatures,
        FloodPredictionLayerIdPrefix,
        layerIds,
        currentLayer
      );
    }

    const infoWindowHtml = `<div class='custom-infowindow'>${infoWindowContent}</div>`;

    new mapboxgl.Popup({ closeOnClick: true })
      .setLngLat(popUpCoordinates)
      .setHTML(infoWindowHtml)
      .addTo(map.current)
      .setMaxWidth('fit-content');
  };

  return {
    init
  };
};

// 拠点情報
const createInfoWindowLocation = (clickLayerIds, locationLayerId, infoWindowFeatures) => {
  let infoWindowContent = '';

  if (clickLayerIds.includes(locationLayerId)) {
    const locationfeatures = infoWindowFeatures.filter((item) => item.layer.id === locationLayerId);
    infoWindowContent += `
      <div class='custom-infowindow-item'>
      <div><strong>Location name ${locationfeatures[0].properties.location_name}</strong></div>
      </div>
    `;
  }
  return infoWindowContent;
};

// ハザードマップ（500m mesh）
const createInfoWindowHazardMap = (infoWindowFeatures, hazardMapLayerIdPrefix, layerIds, currentLayer) => {
  let infoWindowContent = '';

  for (const feature of infoWindowFeatures) {
    if (feature.layer.id.startsWith(hazardMapLayerIdPrefix)) {
      const underscoreLastIndex = feature.layer.id.lastIndexOf('_');
      const tilesetColumn = feature.layer.id.substring(underscoreLastIndex + 1);

      const title = layerIds[feature.layer.id].title;
      const value = feature.properties[tilesetColumn];

      if (!currentLayer.includes(feature.layer.id)) {
        infoWindowContent += `
          <div class='custom-infowindow-item'>
            <div><strong>${title}</strong></div>
            <div>浸水深 depth ${value ? value : '-'}m</div>
            </div>
          `;
        currentLayer.push(feature.layer.id);
      }
    }
  }
  return infoWindowContent;
};

// ハザードマップ（2km mesh）Hmap-60sec_globe_1
const createInfoWindowHazardMapLoRes1 = (infoWindowFeatures, hazardMapLoRes1IdPrefix, layerIds, currentLayer) => {
  let infoWindowContent = '';

  for (const feature of infoWindowFeatures) {
    if (feature.layer.id.startsWith(hazardMapLoRes1IdPrefix)) {
      const title = layerIds[feature.layer.id].title;

      if (!currentLayer.includes(feature.layer.id)) {
        infoWindowContent += `
        <div class='custom-infowindow-item'>
          <div><strong>${title}</strong></div>
          <div>浸水深を確認するには、ズームをしてください</div>
          <div>Please zoom up to check the inundation depth.</div>
        </div>
        `;
      }
    }
  }
  return infoWindowContent;
};
// ハザードマップ（2km mesh）Hmap-60sec_globe_2
const createInfoWindowHazardMapLoRes2 = (infoWindowFeatures, hazardMapLoRes2IdPrefix, layerIds, currentLayer) => {
  let infoWindowContent = '';

  for (const feature of infoWindowFeatures) {
    if (feature.layer.id.startsWith(hazardMapLoRes2IdPrefix)) {
      const title = layerIds[feature.layer.id].title;

      if (!currentLayer.includes(feature.layer.id)) {
        infoWindowContent += `
        <div class='custom-infowindow-item'>
          <div><strong>${title}</strong></div>
          <div>浸水深を確認するには、ズームをしてください</div>
          <div>Please zoom up to check the inundation depth.</div>
        </div>
        `;
      }
    }
  }
  return infoWindowContent;
};
// 洪水頻度変化予測
const createInfoWindowFloodPrediction = (infoWindowFeatures, FloodPredictionLayerIdPrefix, layerIds, currentLayer) => {
  let infoWindowContent = '';

  for (const feature of infoWindowFeatures) {
    if (feature.layer.id.startsWith(FloodPredictionLayerIdPrefix)) {
      const underscoreLastIndex = feature.layer.id.lastIndexOf('_');
      const tilesetColumn = feature.layer.id.substring(underscoreLastIndex + 1);

      const title = layerIds[feature.layer.id].title;
      const value = feature.properties[tilesetColumn];

      if (!currentLayer.includes(feature.layer.id)) {
        infoWindowContent += `
          <div class='custom-infowindow-item'>
          <div><strong>${title}</strong></div>
          <div>洪水頻度変化 Frequency ${value ? value : '-'}yrs</div>
          </div>
          `;
        currentLayer.push(feature.layer.id);
      }
    }
  }
  return infoWindowContent;
};
// 高解像度ハザードマップ（90m mesh）
const createInfoWindowHazardMapHiRes = (infoWindowFeatures, HazardMapHiResLayerIdPrefix, layerIds, currentLayer) => {
  let infoWindowContent = '';

  for (const feature of infoWindowFeatures) {
    if (feature.layer.id.startsWith(HazardMapHiResLayerIdPrefix)) {
      const underscoreLastIndex = feature.layer.id.lastIndexOf('_');
      const tilesetColumn = feature.layer.id.substring(underscoreLastIndex + 1);

      let title = layerIds[feature.layer.id].title;
      const value = feature.properties[tilesetColumn];

      if (!currentLayer.includes(feature.layer.id)) {
        infoWindowContent += `
          <div class='custom-infowindow-item'>
          <div><strong>${title}</strong></div>
          <div>浸水深 depth ${value ? value : '-'}m</div>
          </div>
          `;
        currentLayer.push(feature.layer.id);
      }
    }
  }
  return infoWindowContent;
};

const createInfoWindowTitle = (
  hazardMap,
  hazardMapLoRes1,
  hazardMapLoRes2,
  floodPrediction,
  hazardMapHiRes,
  location,
  financialUserFlg
) => {
  const layerInfo = {};

  // ハザードマップ（500m mesh）
  for (const layerId of hazardMap) {
    const underscoreLastIndex = layerId.lastIndexOf('_');
    const tilesetColumn = layerId.substring(underscoreLastIndex + 1);

    switch (tilesetColumn) {
      case '0':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2020)'
        };
        break;
      case '1':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2020)'
        };
        break;
      case '2':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2050, SSP1-2.6)'
        };
        break;
      case '3':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2050, SSP1-2.6)'
        };
        break;
      case '4':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2080, SSP1-2.6)'
        };
        break;
      case '5':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2080, SSP1-2.6)'
        };
        break;
      case '6':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2050, SSP5-8.5)'
        };
        break;
      case '7':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2050, SSP5-8.5)'
        };
        break;
      case '8':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2080, SSP5-8.5)'
        };
        break;
      case '9':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2080, SSP5-8.5)'
        };
        break;
      case '10':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2.0°C rise)'
        };
        break;
      case '11':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2.0°C rise)'
        };
        break;
      case '12':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 3.0°C rise)'
        };
        break;
      case '13':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 3.0°C rise)'
        };
        break;
      case '14':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 4.0°C rise)'
        };
        break;
      case '15':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 4.0°C rise)'
        };
        break;
      default:
        layerInfo[layerId] = {
          title: ''
        };
    }

    if (financialUserFlg) {
      // {Free, } {Premium, } の表示を消去
      layerInfo[layerId].title = layerInfo[layerId].title.replaceAll('{Free, }', '').replaceAll('{Premium, }', '');
    } else {
      // {Free, } {Premium, } の表示を付与
      layerInfo[layerId].title = layerInfo[layerId].title
        .replaceAll('{Free, }', 'Free, ')
        .replaceAll('{Premium, }', 'Premium, ');
    }
  }

  // ハザードマップ（2km mesh）Hmap-60sec_globe_1
  for (const layerId of hazardMapLoRes1) {
    const underscoreLastIndex = layerId.lastIndexOf('_');
    const tilesetColumn = layerId.substring(underscoreLastIndex + 1);

    switch (tilesetColumn) {
      case '0':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2020)'
        };
        break;
      case '1':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2020)'
        };
        break;
      case '2':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2050, SSP1-2.6)'
        };
        break;
      case '3':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2050, SSP1-2.6)'
        };
        break;
      case '4':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2080, SSP1-2.6)'
        };
        break;
      case '5':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2080, SSP1-2.6)'
        };
        break;
      case '6':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2050, SSP5-8.5)'
        };
        break;
      case '7':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2050, SSP5-8.5)'
        };
        break;
      case '8':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2080, SSP5-8.5)'
        };
        break;
      case '9':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2080, SSP5-8.5)'
        };
        break;
      case '10':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2.0°C rise)'
        };
        break;
      case '11':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 2.0°C rise)'
        };
        break;
      case '12':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 3.0°C rise)'
        };
        break;
      case '13':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 3.0°C rise)'
        };
        break;
      case '14':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 4.0°C rise)'
        };
        break;
      case '15':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }1000years, 4.0°C rise)'
        };
        break;
      default:
        layerInfo[layerId] = {
          title: ''
        };
    }

    if (financialUserFlg) {
      // {Free, } {Premium, } の表示を消去
      layerInfo[layerId].title = layerInfo[layerId].title.replaceAll('{Free, }', '').replaceAll('{Premium, }', '');
    } else {
      // {Free, } {Premium, } の表示を付与
      layerInfo[layerId].title = layerInfo[layerId].title
        .replaceAll('{Free, }', 'Free, ')
        .replaceAll('{Premium, }', 'Premium, ');
    }
  }

  // ハザードマップ（2km mesh）Hmap-60sec_globe_2
  for (const layerId of hazardMapLoRes2) {
    const underscoreLastIndex = layerId.lastIndexOf('_');
    const tilesetColumn = layerId.substring(underscoreLastIndex + 1);

    switch (tilesetColumn) {
      case '8':
        layerInfo[layerId] = {
          title: 'Hazard Map({Free, }100years, 2080, SSP5-8.5)'
        };
        break;
      default:
        layerInfo[layerId] = {
          title: ''
        };
    }

    if (financialUserFlg) {
      // {Free, } {Premium, } の表示を消去
      layerInfo[layerId].title = layerInfo[layerId].title.replaceAll('{Free, }', '').replaceAll('{Premium, }', '');
    } else {
      // {Free, } {Premium, } の表示を付与
      layerInfo[layerId].title = layerInfo[layerId].title
        .replaceAll('{Free, }', 'Free, ')
        .replaceAll('{Premium, }', 'Premium, ');
    }
  }

  // 洪水頻度変化予測
  for (const layerId of floodPrediction) {
    const underscoreLastIndex = layerId.lastIndexOf('_');
    const tilesetColumn = layerId.substring(underscoreLastIndex + 1);

    switch (tilesetColumn) {
      case '0':
        layerInfo[layerId] = {
          title: 'Frequency Change(100years, 2080, SSP1-2.6)'
        };
        break;
      case '1':
        layerInfo[layerId] = {
          title: 'Frequency Change(100years, 2080, SSP5-8.5)'
        };
        break;
      default:
        layerInfo[layerId] = {
          title: ''
        };
    }
  }

  // 高解像度ハザードマップ（90m mesh）
  for (const layerId of hazardMapHiRes) {
    const underscoreLastIndex = layerId.lastIndexOf('_');
    const tilesetColumn = layerId.substring(underscoreLastIndex + 1);

    switch (tilesetColumn) {
      case '0':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2020)'
        };
        break;
      case '1':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2020)'
        };
        break;
      case '2':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2050, SSP1-2.6)'
        };
        break;
      case '3':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2050, SSP1-2.6)'
        };
        break;
      case '4':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2080, SSP1-2.6)'
        };
        break;
      case '5':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2080, SSP1-2.6)'
        };
        break;
      case '6':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2050, SSP5-8.5)'
        };
        break;
      case '7':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2050, SSP5-8.5)'
        };
        break;
      case '8':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2080, SSP5-8.5)'
        };
        break;
      case '9':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2080, SSP5-8.5)'
        };
        break;
      case '10':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 2.0°C rise)'
        };
        break;
      case '11':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 2.0°C rise)'
        };
        break;
      case '12':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 3.0°C rise)'
        };
        break;
      case '13':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 3.0°C rise)'
        };
        break;
      case '14':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }100years, 4.0°C rise)'
        };
        break;
      case '15':
        layerInfo[layerId] = {
          title: 'Hazard Map({Premium, }1000years, 4.0°C rise)'
        };
        break;
      default:
        layerInfo[layerId] = {
          title: ''
        };
    }
    if (financialUserFlg) {
      // {Free, } {Premium, } の表示を消去
      layerInfo[layerId].title = layerInfo[layerId].title.replaceAll('{Free, }', '').replaceAll('{Premium, }', '');
    } else {
      // {Free, } {Premium, } の表示を付与
      layerInfo[layerId].title = layerInfo[layerId].title
        .replaceAll('{Free, }', 'Free, ')
        .replaceAll('{Premium, }', 'Premium, ');
    }
  }

  layerInfo[location] = {};

  return layerInfo;
};

export default useInfoWindow;
